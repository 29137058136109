import { CLEAR_FORM, SET_FORM_DATA } from "../types/form";

import _ from "lodash";
const initialState = {
  clearForm: false,
};
export default function formReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_FORM_DATA:
      return {
        ...state,
        [payload.key]: payload.value,
      };
    case CLEAR_FORM:
      return {
        ...state,
        clearForm: payload,
      };

    default:
      return state;
  }
}
