import { SET_SPRAY_DATA } from "../types/spray/spray";
import { authServices } from "services/auth.services";

const initialState = {
  sun_rise_offset_hours: "",
  sun_set_offset_hours: "",
  min_temperature_c: "",
  max_temperature_c: "",
  min_humidity: "",
  max_humidity: "",
  min_wind_speed: "",
  max_wind_speed: "",
  precipitation: "",
  rain_free_hours: "",
};
export default function sprayDataReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_SPRAY_DATA:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
}
