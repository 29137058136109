export const SET_SENSING_DATA = "SET_SENSING_DATA";
export const READ_FARM_IMAGES_DATES = "READ_FARM_IMAGES_DATES";
export const READ_FARM_VALVES_DETAILS = "READ_FARM_VALVES_DETAILS";
export const REFRESH_MAP = "REFRESH_MAP";
export const SENSING_ERROR = "SENSING_ERROR";
export const CLEAR_SENSING = "CLEAR_SENSING";
export const START_SENSINGS_RELOAD = "START_SENSINGS_RELOAD";
export const FINISHED_SENSINGS_RELOAD = "FINISHED_SENSINGS_RELOAD";
export const READ_SENSING_CHARTS = "READ_SENSING_CHARTS";
export const READ_VALVES_WITH_FILTER = "READ_VALVES_WITH_FILTER";
export const READ_ALL_VALVE_WITH_FARM_ID = "READ_ALL_VALVE_WITH_FARM_ID";
export const GEO_VALVE = "GEO_VALVE";
export const READ_ONE_VALVE = "READ_ONE_VALVE";
export const VALVE_REFRESH_MAP = "VALVE_REFRESH_MAP";
export const SHOW_COLORS = "SHOW_COLORS";
export const READ_VALVES_BY_FIELD_ID = "READ_VALVES_BY_FIELD_ID";
export const CLEAR_VALVES = "CLEAR_VALVES";
