import { READ_VALVES_SENSOR_WITH_FILTER } from "../../types/valves/valves";
const initialState = {
  valesSensor: [],
  next: null,
  loading: false,
  readable: false,
  errors: [],
};
export default function valvesSensorReducer(state = initialState, action) {
  const { type, payload } = action;
  // console.log({ payloadss: payload });
  switch (type) {
    case READ_VALVES_SENSOR_WITH_FILTER:
      return {
        ...state,
        valesSensor: { payload },
        readable: true,
      };

    default:
      return state;
  }
}
