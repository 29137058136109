import { get } from "@/redux/crud/get";
import { patch } from "@/redux/crud/patch";
import { post } from "../../crud/post";
import {
  CLEAR_PIVOT_FIELD,
  CLEAR_PIVOT_GEO_FIELD,
  READ_PIVOT_GEO_FIELDS,
  READ_PIVOT_ONE_FIELD,
  SET_PIVOT_FIELD_DATA,
  READ_PIVOT_FIELDS,
  READ_PIVOT_ACTIVITY_LOG,
  CREATE_PIVOT_FIELD,
  READ_CLUSTERS_PIVOT_FIELD_DETAILS,
  READ_PIVOT_CROP_FIELD_DETAILS,
  READ_PIVOT_CROP_FIELDS_ON_MAP,
  READ_ONE_PIVOT_CROP_FIELD,
  READ_PIVOT_CROP_FIELDS,
  READ_PIVOT_GEO_CROP_FIELDS,
  READ_WATER_CROP_CHART,
  READ_CROP_FLASK_CHART_DATA,
  READ_PIVOT_IRRIGATION_DETAILS,
  READ_VARIETIES,
  READ_CLUSTERS_PIVOT_FIELD,
  READ_ONE_CLUSTER,
  READ_DETAILS_CLUSTER_CHART,
  READ_CLUSTER_DETAILS_ACTIVITIES,
  READ_IRR_NEXT_DAY_CROP_FIELDS,
  UPDATE_PIVOT_FIELD,
  CLEAR_PIVOT_CROP_FIELD,
  CLEAR_PIVOT_GEO_CROP_FIELD,
  READ_CROP_FLASK_EVAPOTRANSPIRATION_CHART_DATA,
  READ_WATER_CROP_EVAPOTRANSPIRATION_CHART,
  READ_WATER_CROP_RERERENCE_CHART,
  CLEAR_CROP_FLASK_CHART_DATA,
  READ_IRR_LAYERS_CROP_FIELDS,
  READ_IRR_AQWA_LAYER_CROP_FIELDS,
  READ_CROP_FIELDS_LOGS,
  READ_CLUSTERS_PIVOT_FIELD_WITH_FILTER,
  READ_CROP_FIELDS_LOCATIONS_FOR_ADMINS,
  CLEAR_CROP_FIELDS_LOCATIONS_FOR_ADMINS,
} from "../../types/pivot/fields";

export const setCropFieldData = (payload: any) => (dispatch: any) => {
  dispatch({ type: SET_PIVOT_FIELD_DATA, payload });
};
export const readVarieties = (): any =>
  get({
    url: process.env.NEXT_PUBLIC_BASE_URL + "varities/",
    title: "Pivot_Field",
    successType: READ_VARIETIES,
  });
export const readPivotFields = (
  query: string = "",
  irrigation_type = "pivot"
): any =>
  // read all pivot fields
  get({
    url:
      process.env.NEXT_PUBLIC_BASE_URL + `${irrigation_type}-fields/${query}`,
    title: "Pivot_Field",
    successType: READ_PIVOT_FIELDS,
  });
export const readOnePivotField = (
  id: number | string = 1,
  irrigation_type = "pivot"
): any =>
  // react one pivot field
  get({
    url: process.env.NEXT_PUBLIC_BASE_URL + `${irrigation_type}-fields/${id}/`,
    title: "Pivot_Field",
    successType: READ_PIVOT_ONE_FIELD,
  });
export const readCropFields = (
  query: string = "",
  irrigation_type = "pivot"
): any =>
  // read all pivot crop fields or with a query for a specific farm
  get({
    url:
      process.env.NEXT_PUBLIC_BASE_URL +
      `${irrigation_type}-crop-fields/${query}`,
    title: "Pivot_Field",
    successType: READ_PIVOT_CROP_FIELDS,
  });

export const readIrrNextDayCropFields = (farm_id: string = ""): any =>
  // read all pivot crop fields or with a query for a specific farm
  get({
    url:
      process.env.NEXT_PUBLIC_BASE_URL +
      `irriwatch-logs/next-irrigation/${farm_id}/`,
    title: "Pivot_Field",
    successType: READ_IRR_NEXT_DAY_CROP_FIELDS,
  });

export const readIrrAQWALayerCropFields = (
  farm_id: string = "",
  title: string = "",
  irrigation_type = "14"
): any =>
  get({
    url:
      process.env.NEXT_PUBLIC_BASE_URL_FLASK +
      `farms/${farm_id}/layers/${
        irrigation_type === "pivot" ? "14" : "37"
      } /${title}/`,
    title: "Pivot_Field",
    successType: READ_IRR_AQWA_LAYER_CROP_FIELDS,
  });
export const readIrrLayersCropFields = (
  farm_id: string = "",
  title: string = "",
  irrigation_type = "14"
): any =>
  get({
    url:
      process.env.NEXT_PUBLIC_BASE_URL +
      `farms/${farm_id}/layers/${
        irrigation_type === "pivot" ? "14" : "37"
      }/${title}/`,

    title: "Pivot_Field",
    successType: READ_IRR_LAYERS_CROP_FIELDS,
  });

export const readOneCropFiled = (
  id: number | string = 1,
  irrigation_type = "pivot"
): any =>
  // read one crop field by ID
  get({
    url:
      process.env.NEXT_PUBLIC_BASE_URL +
      `${irrigation_type}-crop-fields/${id}/`,
    title: "Pivot_Field",
    successType: READ_ONE_PIVOT_CROP_FIELD,
  });

export const readPivotGeoFields = (id: number | string): any =>
  // read pivot geo crop feilds for a spicific farm using farm id witg varaieties

  get({
    url: process.env.NEXT_PUBLIC_BASE_URL + `farms/${id}/gboundaries/`,
    title: "Pivot_Field",
    successType: READ_PIVOT_GEO_FIELDS,
  });

export const readPivotGeoCropFieldsWithFarmId = (id: number | string): any =>
  // read pivot geo fields for a farm by farm id
  get({
    url: process.env.NEXT_PUBLIC_BASE_URL + `farms/${id}/crops/`,
    title: "Pivot_Field",
    successType: READ_PIVOT_GEO_CROP_FIELDS,
  });
export const readPivotCropFieldsOnMap = (
  id: string | number = 1,
  irrigation_type = "pivot"
): any =>
  //to get crop field on map with variety for one pivot field
  get({
    url:
      process.env.NEXT_PUBLIC_BASE_URL +
      `${irrigation_type}-fields/${id}/crops-onmap/`,
    title: "Pivot_Field",
    successType: READ_PIVOT_CROP_FIELDS_ON_MAP,
  });
export const readPivotCropFieldDetails = (
  id: number | string = 1,
  irrigation_type = "pivot"
): any =>
  // get all crop fields details for a specifc field
  get({
    url:
      process.env.NEXT_PUBLIC_BASE_URL +
      `${irrigation_type}-crop-fields/?field_id__id=${id}`,
    title: "Pivot_Field",
    successType: READ_PIVOT_CROP_FIELD_DETAILS,
  });
export const readClustersPivotFieldsDetails = (
  id: number | string = 1,
  type = undefined,
  irrigation_type = "pivot"
): any => {
  const query = type ? `&cluster_type=${type}` : "";
  return get({
    url:
      process.env.NEXT_PUBLIC_BASE_URL +
      `clusters/?${irrigation_type}_field_id__id=${id}${query}`,
    title: "Pivot_Field",
    successType: READ_CLUSTERS_PIVOT_FIELD_DETAILS,
  });
};
export const readPivotActivityLog = (
  id: number | string = 1,
  activity: string = "agro",
  startDate: string = "2021-10-10",
  endDate: string = new Date()
    .toLocaleDateString("en-GB")
    .split("/")
    .reverse()
    .join("-"),
  irrigation_type = "pivot"
): any =>
  // read activities for pivot fields
  get({
    url:
      process.env.NEXT_PUBLIC_BASE_URL +
      `${irrigation_type}cropagro-activity-logs/fields/${
        activity === "" ? "agro" : activity
      }/${id}/${startDate === "" ? "2021-10-10" : startDate}/${
        endDate === ""
          ? new Date()
              .toLocaleDateString("en-GB")
              .split("/")
              .reverse()
              .join("-")
          : endDate
      }/`,
    title: "Pivot_Field",
    successType: READ_PIVOT_ACTIVITY_LOG,
  });
export const readPivotIrricationDetails = (
  farm_id: number | string = 1,
  startDate: string = "2021-06-10",
  endDate: string = new Date()
    .toLocaleDateString("en-GB")
    .split("/")
    .reverse()
    .join("-")
): any =>
  // read activities for pivot fields
  get({
    url:
      process.env.NEXT_PUBLIC_BASE_URL +
      `farms/${farm_id}/crop-irrigation/${startDate}/${endDate}/`,
    title: "Pivot_Field",
    successType: READ_PIVOT_IRRIGATION_DETAILS,
  });
export const readCropWaterChartLog = (
  id: number | string = 1,
  category: string,
  startDate: string,
  endDate: string,
  irrigation_type = "pivot"
): any =>
  get({
    url:
      category === "area_percentage_requiring_irrigation"
        ? process.env.NEXT_PUBLIC_BASE_URL +
          `${irrigation_type}-crop-fields/${id}/irrigation_details_nodates/area_percentage_requiring_irrigation/`
        : process.env.NEXT_PUBLIC_BASE_URL +
          `${irrigation_type}-crop-fields/${id}/irrigation_details/${category}/${startDate}/${endDate}/`,
    title: "Pivot_Field",
    successType: READ_WATER_CROP_CHART,
  });
export const readCropWaterEvapoTranspirationChartLog = (
  id: number | string = 1,
  category: string = "actual_evapotranspiration",
  startDate: string,
  endDate: string
): any =>
  get({
    url:
      process.env.NEXT_PUBLIC_BASE_URL +
      `pivot-crop-fields/${id}/irrigation_details/${category}/${startDate}/${endDate}/`,
    title: "Pivot_Field",
    successType: READ_WATER_CROP_EVAPOTRANSPIRATION_CHART,
  });
export const readCropWaterReferenceChartLog = (
  id: number | string = 1,
  category: string = "ref_evapotranspiration",
  startDate: string,
  endDate: string
): any =>
  get({
    url:
      process.env.NEXT_PUBLIC_BASE_URL +
      `pivot-crop-fields/${id}/irrigation_details/${category}/${startDate}/${endDate}/`,
    title: "Pivot_Field",
    successType: READ_WATER_CROP_RERERENCE_CHART,
  });
export const readCropFlaskChartLog = (
  id: number | string = 1,
  irrigation_type = "pivot"
): any =>
  get({
    url:
      process.env.NEXT_PUBLIC_BASE_URL +
      `${irrigation_type}-crop-fields/${id}/irriwatch/`,
    title: "Pivot_Field",
    successType: READ_CROP_FLASK_CHART_DATA,
  });
export const readCropFlaskEvapotranspirationChartLog = (
  id: number | string = 1
): any =>
  get({
    url: process.env.NEXT_PUBLIC_BASE_URL + `pivot-crop-fields/${1}/irriwatch/`,
    title: "Pivot_Field",
    successType: READ_CROP_FLASK_EVAPOTRANSPIRATION_CHART_DATA,
  });
export const updateField = (formData: any): any =>
  patch({
    url: process.env.NEXT_PUBLIC_BASE_URL + "pivot-fields/" + formData.id + "/",
    title: UPDATE_PIVOT_FIELD,
    formData,
  });

export const creatField = (formData: any): any =>
  post({
    url: process.env.NEXT_PUBLIC_BASE_URL + "pivot-fields/",
    title: "Pivot_Field",
    successType: CREATE_PIVOT_FIELD,
    formData,
  });

export const readClusterCropField = (
  crop: string,
  type: "automatic" | "manual",
  category: string,
  date
): any =>
  get({
    url:
      process.env.NEXT_PUBLIC_BASE_URL +
      `clusters/show/${type}/${category}/${crop}/${date}`,
    title: "Pivot_Field",
    successType: READ_CLUSTERS_PIVOT_FIELD,
  });
export const readClusterCropFieldWithFilter = (
  crop: string,
  type: "automatic" | "manual",
  category: string,
  date,
  index
): any =>
  get({
    url:
      process.env.NEXT_PUBLIC_BASE_URL +
      `clusters/show-filtered/${type}/${category}/${crop}/${index}/${date}/`,
    title: "Pivot_Field",
    successType: READ_CLUSTERS_PIVOT_FIELD_WITH_FILTER,
  });
export const readOneCluster = (id: string = ""): any =>
  get({
    url: process.env.NEXT_PUBLIC_BASE_URL + `clusters/${id}/`,
    title: "Pivot_Field",
    successType: READ_ONE_CLUSTER,
  });
export const readDetailsClusterChart = (id: string = ""): any =>
  get({
    url: process.env.NEXT_PUBLIC_BASE_URL + `rsdata-logs/nvdi/cluster/${id}/`,
    title: "Pivot_Field",
    successType: READ_DETAILS_CLUSTER_CHART,
  });
export const readDetailsClusterActivities = (
  id: string = "",
  category = "agro",
  startDate: string = "2021-10-10",
  endDate: string = new Date()
    .toLocaleDateString("en-GB")
    .split("/")
    .reverse()
    .join("-")
): any =>
  get({
    url:
      process.env.NEXT_PUBLIC_BASE_URL +
      `pivotcropagro-activity-logs/clusters/${category}/${id}/${startDate}/${endDate}`,
    title: "Pivot_Field",
    successType: READ_CLUSTER_DETAILS_ACTIVITIES,
  });

export const readLogsCropFields = (
  id: number | string,
  irrigation_type = "pivot"
): any =>
  get({
    url:
      process.env.NEXT_PUBLIC_BASE_URL +
      `${irrigation_type}cropagro-activity-logs/advanced-details/${id}/`,
    title: "Pivot_Field",
    successType: READ_CROP_FIELDS_LOGS,
  });

export const clearPivotField = () => (dispatch: any) => {
  dispatch({ type: CLEAR_PIVOT_FIELD });
};
export const clearPivotGeoField = () => (dispatch: any) => {
  dispatch({ type: CLEAR_PIVOT_GEO_FIELD });
};
export const clearPivotCropField = () => (dispatch: any) => {
  dispatch({ type: CLEAR_PIVOT_CROP_FIELD });
};
export const clearPivotGeoCropField = () => (dispatch: any) => {
  dispatch({ type: CLEAR_PIVOT_GEO_CROP_FIELD });
};
export const clearCropFlaskChart = () => (dispatch: any) => {
  dispatch({ type: CLEAR_CROP_FLASK_CHART_DATA });
};
export const readClusterKPIS = (formData) =>
  post({
    url: process.env.NEXT_PUBLIC_BASE_URL + "clusters/kpi-graph/",
    formData,
    title: "clusterKPIS",
  });

export const readCropFieldsLocationForAdmins = () =>
  get({
    url: process.env.NEXT_PUBLIC_BASE_URL + `crop-locations/all-locations/`,
    title: "Pivot_crop_Fields",
    successType: READ_CROP_FIELDS_LOCATIONS_FOR_ADMINS,
  });

export const clearCropFieldLocationForAdmins = () => (dispatch: any) => {
  dispatch({ type: CLEAR_CROP_FIELDS_LOCATIONS_FOR_ADMINS });
};
