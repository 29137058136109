export const SET_SENSOR_DATA = "SET_SENSOR_DATA";

export const READ_GEO_SENSORS = "READ_GEO_SENSORS";
export const READ_SENSORS = "READ_SENSORS";
export const READ_ONE_SENSOR = "READ_ONE_SENSOR";
export const READ_SENSOR_READING = "READ_SENSOR_READING";
export const CREATE_SENSOR = "CREATE_SENSOR";
export const UPDATE_SENSOR = "UPDATE_SENSOR";
export const DELETE_SENSOR = "DELETE_SENSOR";
export const SEARCH_SENSOR_FILTER = "SEARCH_SENSOR_FILTER";
export const SENSOR_ERROR = "SENSOR_ERROR";
export const CLEAR_SENSOR = "CLEAR_SENSOR";
export const START_SENSORS_RELOAD = "START_SENSORS_RELOAD";
export const FINISHED_SENSORS_RELOAD = "FINISHED_SENSORS_RELOAD";

export const SENSORS_DATE_FROM = "SENSORS_DATE_FROM";
export const SENSORS_DATE_TO = "SENSORS_DATE_TO";
