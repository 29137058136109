import React from "react";
import LoaderImage from "assets/svg/loader.svg";
import Image from "next/image";
import LoaderWhite from "assets/svg/loader-white.svg";
type PropsType = { className?: any; color?: string; zIndex?: number };
const Loader: React.FC<PropsType> = ({
  zIndex,
  className = "",
  color = "green",
}) => {
  return (
    <div
      style={{ zIndex }}
      className="flex items-center justify-center w-full h-full "
    >
      {color === "green" ? (
        <div
          className={`w-[80px] h-[80px] animate-spin border-[10px] border-[#d3d1d1] border-b-main rounded-full inline-block	 ${className}`}
        ></div>
      ) : (
        <div
          className={`w-[80px] h-[80px] animate-spin border-[10px] border-[#d3d1d1] border-b-[#fff] rounded-full inline-block	 ${className}`}
        ></div>
      )}
    </div>
  );
};
export default Loader;
