import Image from "next/image";
import List from "assets/img/list-svgre.png";
import Category from "assets/img/Category.png";
function switchButtons({ active, setActive }) {
  return (
    <div className="flex gap-3">
      <div
        onClick={() => setActive("card")}
        className={` flex items-center justify-center bg-white ${
          active === "card" ? "border-2 border-black" : " "
        }  p-2 rounded-md cursor-pointer`}
      >
        <div className="xl:w-[13px] xl:h-[13px] w-[10px] h-[10px] relative">
          <Image
            src={Category}
            className="object-fill "
            layout={"fill"}
            alt="img"
          />
        </div>
      </div>
      <div
        onClick={() => setActive("table")}
        className={`p-2  rounded-md cursor-pointer flex items-center justify-center bg-white ${
          active === "table" ? "border-2 border-black" : " "
        } `}
      >
        <div className="xl:w-[13px] xl:h-[13px] w-[10px] h-[10px] relative">
          <Image
            src={List}
            alt="img"
            className="object-fill "
            layout={"fill"}
          />
        </div>
      </div>
    </div>
  );
}

export default switchButtons;
