export const responsiveAsideAlignment = (language: string): string => {
  if (language === "en") {
    return "ml-[4.3rem] sm:ml-20 xl:ml-24 p-0.5";
  } else {
    return "mr-[4.3rem] sm:mr-20 xl:mr-24 p-0.5";
  }
};
export const responsiveHoverElement = (language: string): string => {
  if (language === "en") {
    return "hover:-translate-x-2 pr-1";
  } else {
    return "hover:translate-x-2 pl-1";
  }
};
export const responsiveActiveElement = (
  currentPath: string,
  language: string,
  router: any
): string => {
  if (router.pathname.includes(currentPath) && language === "en") {
    return "-translate-x-2 pr-1";
  } else if (router.pathname.includes(currentPath) && language === "ar") {
    return "translate-x-2 pl-1";
  } else {
    return "";
  }
};
export const responsiveDirection = (language: string): string => {
  if (language === "ar") {
    return "rtl";
  } else {
    return "ltr";
  }
};
export const responsiveFlexRow = (language: string): string => {
  if (language === "en") {
    return "flex-row";
  } else {
    return "flex-row-reverse";
  }
};
