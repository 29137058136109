import { SET_VALVE_DATA } from "../../types/valves/valveData";
import { authServices } from "services/auth.services";

const initialState = {
  id: "",
  entity_type_id: "",
};
export default function valveDataReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_VALVE_DATA:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
}
