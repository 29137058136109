import {
  READ_GEOS,
  READ_ONE_GEO,
  CREATE_GEO,
  UPDATE_GEO,
  DELETE_GEO,
  CLEAR_GEO,
  START_GEOS_RELOAD,
  FINISHED_GEOS_RELOAD,
  GEO_ERROR,
} from "../types/geos";

const initialState = {
  geos: [],
  geo_geos: [],
  geo: {},
  form_geo: {},
  loading: false,
  readable: false,
  errors: [],
};
export default function geosReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case READ_GEOS:
      return {
        ...state,
        geos: [...payload.results],
        readable: true,
      };
    case READ_ONE_GEO:
      return {
        ...state,
        geo: { ...payload },
      };

    case CREATE_GEO:
      return {
        ...state,
        geos: [payload, ...state.geos],
        geo: { ...payload },
      };
    case UPDATE_GEO:
      return {
        ...state,
        geos: [
          ...state.geos.map((geo) =>
            geo.id === payload.geo.id ? payload.geo : geo
          ),
        ],
      };
    case DELETE_GEO:
      return {
        ...state,
        geos: [...state.geos.filter((geo) => geo.id !== payload.geo.id)],
      };
    case CLEAR_GEO:
      return {
        ...state,
        geo: {},
      };

    case START_GEOS_RELOAD:
      return {
        ...state,
        loading: true,
      };
    case FINISHED_GEOS_RELOAD:
      return {
        ...state,
        loading: false,
      };
    case GEO_ERROR:
      return {
        ...state,
        errors: [...state.errors, payload],
      };
    default:
      return state;
  }
}
