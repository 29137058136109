import {
  SET_SENSOR_DATA,
  READ_SENSORS,
  READ_ONE_SENSOR,
  READ_SENSOR_READING,
  START_SENSORS_RELOAD,
  FINISHED_SENSORS_RELOAD,
  SENSOR_ERROR,
  SENSORS_DATE_FROM,
  SENSORS_DATE_TO,
} from "../../types/tree/sensors";

const initialState = {
  sensors: [],
  sensor: {},
  loading: false,
  sensor_readings: [],
  chart_readings: [],
  readable: false,
  errors: [],
  sensorsDateFrom: "",
  sensorsDateTo: "",
};
export default function treesReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_SENSOR_DATA:
      return {
        ...state,
        [payload.key]: payload.value,
      };
    case READ_SENSORS:
      return {
        ...state,
        sensors: payload,
        readable: true,
      };

    case READ_ONE_SENSOR:
      return {
        ...state,
        sensor: payload,
      };
    case READ_SENSOR_READING:
      return {
        ...state,
        sensor_readings: payload,
      };
    case START_SENSORS_RELOAD:
      return {
        ...state,
        loading: true,
      };
    case FINISHED_SENSORS_RELOAD:
      return {
        ...state,
        loading: false,
      };
    case SENSOR_ERROR:
      return {
        ...state,
        // errors: [...state.errors, payload],
      };

    default:
      return state;
  }
}
