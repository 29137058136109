export const READ_GEOS = "READ_GEOS";
export const READ_ONE_GEO = "READ_ONE_GEO";
export const CREATE_GEO = "CREATE_GEO";
export const UPDATE_GEO = "UPDATE_GEO";
export const DELETE_GEO = "DELETE_GEO";
export const SEARCH_GEO_FILTER = "SEARCH_GEO_FILTER";
export const GEO_ERROR = "GEO_ERROR";
export const CLEAR_GEO = "CLEAR_GEO";
export const START_GEOS_RELOAD = "START_GEOS_RELOAD";
export const FINISHED_GEOS_RELOAD = "FINISHED_GEOS_RELOAD";
