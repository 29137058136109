import { SET_PROGRAMS_DATA } from "../types/programs/programs-id";
import { authServices } from "services/auth.services";

const initialState = {
  program_id: "",
  task_id: "",
  farming_type: "",
  activity_type: "",
  activity_category: "",
  target_level: "",
  subcategory: "",
  program_name: "",
  active_program_name: "",
  id: "",
  details: "",
  program_type: "",
  activity_subcategory: "",
  treeOrCrop: "",
  plant_name: "",
};
export default function programDataReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_PROGRAMS_DATA:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
}
