import {
  SET_INVENTORIES_DATA,
  READ_INVENTORIES,
  READ_GEO_INVENTORIES,
  READ_ONE_INVENTORY,
  READ_INVENTORIES_ACTIVITIES,
  READ_INVENTORIES_STATS,
  CLEAR_GEO_INVENTORIES,
  START_INVENTORIES_RELOAD,
  FINISHED_INVENTORIES_RELOAD,
  INVENTORIES_ERROR,
  READ_INVENTORIES_BY_CATEGORIES,
  READ_INVENTORIES_GLOBAL_ITEMS,
  CREATE_IVENTORY_ITEM,
  UPDATE_INVENTORY,
  SET_INVENTORIES_SUPERVISORS,
} from "../types/inventories";
const initialState = {
  inventories: [],
  inventory: {},
  geo_inventories: [],
  inventories_activities: [],
  inventories_stats: [],
  inventories_global_items: [],
  loading: false,
  readable: false,
  errors: [],
};

export default function inventoriesReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case READ_INVENTORIES:
      return {
        ...state,
        inventories: payload.results ?? [],
        readable: true,
      };
    // case CREATE_IVENTORY_ITEM:
    //   return {
    //     ...state,
    //     inventories_stats: payload ?? [],
    //   };
    case SET_INVENTORIES_DATA:
      return {
        ...state,
        [payload.key]: payload.value,
      };
    case READ_ONE_INVENTORY:
      return {
        ...state,
        inventory: payload ?? {},
        readable: true,
      };
    case READ_GEO_INVENTORIES:
      return {
        ...state,
        geo_inventories: payload ?? [],
        readable: true,
      };
    case READ_INVENTORIES_ACTIVITIES:
      return {
        ...state,
        inventories_activities: payload?.results ?? [],
      };
    case READ_INVENTORIES_BY_CATEGORIES:
      return {
        ...state,
        inventories: payload?.results ?? [],
        readable: true,
      };
    case READ_INVENTORIES_GLOBAL_ITEMS:
      return {
        ...state,
        inventories_global_items: payload.results ?? [],
      };
    case READ_INVENTORIES_STATS:
      return {
        ...state,
        inventories_stats: payload ?? [],
      };
    case CLEAR_GEO_INVENTORIES:
      return {
        ...state,
        geo_inventories: [],
      };

    case START_INVENTORIES_RELOAD:
      return {
        ...state,
        loading: true,
      };
    case FINISHED_INVENTORIES_RELOAD:
      return {
        ...state,
        loading: false,
      };
    case INVENTORIES_ERROR:
      return {
        ...state,
      };
    default:
      return state;
  }
}
