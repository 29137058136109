export const SET_TREE_TAB = "SET_TREE_TAB";
export const TOGGLE_DETAILS = "TOGGLE_DETAILS";
export const TOGGLE_SUB_DETAILS = "TOGGLE_SUB_DETAILS";
export const SET_CURRENT_TREE_TAB = "SET_CURRENT_TREE_TAB";
export const TABLE_TAPS = "TABLE_TAPS";
export const TOGGLE_WEATHER = "TOGGLE_WEATHER";
export const TOGGLE_LAYER = "TOGGLE_LAYER";
export const ACTIVE_NAVIGATE = "ACTIVE_NAVIGATE";
export const DEACTIVE_NAVIGATE = "DEACTIVE_NAVIGATE";
export const IRRIGATION_CHART = "IRRIGATION_CHART";
export const TOGGLE_SPRAY = "TOGGLE_SPRAY";
export const TOGGLE_ADD_CLUSTER = "TOGGLE_ADD_CLUSTER";
