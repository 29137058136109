import { combineReducers } from "redux";
import usersReducer from "./users";
import alertReducer from "./alert";
import farmsReducer from "./farms";
import assetsReducer from "./assets";
import navReducer from "./nav";
import treeFieldsReducer from "./tree/fields";
import cropFieldsReducer from "./pivot/fields";
import pivotPestDiseasesReducer from "./pivot/pest-disease";
import treePestDiseasesReducer from "./tree/pest-disease";
import reportDataReducer from "./report";
import treesReducer from "./tree/trees";
import programReducer from "./programs";
import geoReducer from "./geo";
import sensorsReducer from "./tree/sensors";
import weatherReducer from "./weather";
import waterNetworksReducer from "./tree/water-networks";
import electricityNetworksReducer from "./tree/electricity-networks";
import sprayDataReducer from "./spray";
import valvesReducer from "./valves/valves";
import valvesSensorReducer from "./valves/valveSensor";
import sensingReducer from "./pivot/sensing";
import valveDataReducer from "./valves/valveData";
import inventoriesReducer from "./inventories";
import sensingTreeReducer from "./tree/sensing";
import soilSamplesReducer from "./pivot/soilSamples";
import reportReducer from "./reports/index";
import notAuthorizedAlertReducer from "./notAuthorizedAlert";
import formReducer from "./form";
import policyDataReducer from "./access-policy";
import drawValveDataReducer from "./valves/drawValves";
export default combineReducers({
  navReducer,
  alertReducer,
  geoReducer,
  farmsReducer,
  treeFieldsReducer,
  cropFieldsReducer,
  treesReducer,
  waterNetworksReducer,
  usersReducer,
  programReducer,
  sensorsReducer,
  weatherReducer,
  electricityNetworksReducer,
  assetsReducer,
  sensingReducer,
  sprayDataReducer,
  inventoriesReducer,
  valvesReducer,
  valvesSensorReducer,
  valveDataReducer,
  pivotPestDiseasesReducer,
  treePestDiseasesReducer,
  sensingTreeReducer,
  reportDataReducer,
  soilSamplesReducer,
  reportReducer,
  formReducer,
  policyDataReducer,
  notAuthorizedAlertReducer,
  drawValveDataReducer,
});
