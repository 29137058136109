import axios from "axios";
import Cookie from "js-cookie";
import { authServices } from "services/auth.services";
import { strToBool } from "utils/strToBool";

async function createFarm(data) {
  try {
    let res = await axios.post(
      process.env.NEXT_PUBLIC_BASE_URL + "farms/",
      data,
      {
        headers: {
          Authorization: `Bearer ${Cookie.get("token")}`,
        },
      }
    );
    if (res.status === 201) {
      return res;
    } else {
    }
  } catch (e) {
    console.log({ errData: e });
  }
}
// async function createFarm(formData) {
//   post({
//     url: process.env.NEXT_PUBLIC_BASE_URL + "farms/",
//     formData,
//   });
// }
async function generateQR(data) {
  try {
    let res = await axios.post(
      process.env.NEXT_PUBLIC_BASE_URL + "trees/generate-qr-codes/",
      data,
      {
        headers: {
          Authorization: `Bearer ${Cookie.get("token")}`,
        },
      }
    );
    if (res.status === 201 || res.status === 200) {
      console.log({ resData: res });
      return res;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}
export async function deleteFarm(id) {
  try {
    let res = await axios.delete(
      process.env.NEXT_PUBLIC_BASE_URL + "farms/" + id,
      {
        headers: {
          Authorization: `Bearer ${Cookie.get("token")}`,
        },
      }
    );
    if (res.status === 204) {
      console.log({ resData: res });
    } else {
    }
  } catch (e) {}
}
export const farmServices = { createFarm, deleteFarm, generateQR };
