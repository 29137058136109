import Delete from "assets/img/deleteX.png";
import Edit from "assets/img/editX.png";
import View from "assets/img/showX.png";
import Tree from "assets/img/irrigationTypes/date.png";
import Pivot from "assets/img/irrigationTypes/pivot.png";
import Drip from "assets/img/irrigationTypes/dripIrr.png";
import Flood from "assets/img/irrigationTypes/immersion.png";
import Image from "next/image";
import _objI from "@/utils/_objI";
import { useTranslation } from "react-i18next";
import { authServices } from "services/auth.services";
import { isArabic } from "@/utils/isArabic";

const FarmCard = ({
  data,
  setShowModel,
  setDeleteId,
  showModel,
  viewFarm,
  editFarm,
}) => {
  const { t, i18n } = useTranslation();

  const canDelete =
    authServices?.userValue?.policy_data?.farm_integration?.actions?.includes?.(
      "delete"
    );

  const canEdit =
    authServices?.userValue?.policy_data?.farm_integration?.actions?.includes?.(
      "edit"
    );

  const handleImages = {
    pivot: Pivot,
    drip: Drip,
    flood: Flood,
  };
  return (
    <div className="bg-white text-xs lg:text-md w-full md:w-[335px]  xl:w-[390px] p-2 rounded-lg relative">
      <div className="flex items-center justify-between p-3 -m-2">
        <div
          onClick={() => viewFarm(data)}
          className="flex items-center w-full h-full gap-3 cursor-pointer"
        >
          <div className="min-w-[75px] min-h-[75px] xl:w-[98px] xl:h-[97px] relative   ">
            <Image
              priority={true}
              src={
                data?.farming_type === "tree"
                  ? Tree
                  : handleImages?.[data?.irrigation_type]
              }
              alt="farm"
              className="object-fill rounded-lg"
              quality={50}
              layout={"fill"}
            />
          </div>

          <h2
            style={{
              fontFamily: isArabic(data?.farm_name)
                ? "Almarai"
                : "Baloo-regular",
            }}
            className={` p-1 text-[20px] font-semibold leading-5 text-[#1C1C1C]`}
          >
            {data?.farm_name}
          </h2>
        </div>
      </div>

      <table className="w-full text-[12.5px]">
        <tbody>
          <tr className="">
            <td className="text-[#585858] p-1">{t("Region Name")}</td>
            <td
              style={{
                fontFamily: isArabic(data?.farm_name)
                  ? "Almarai"
                  : "Baloo-regular",
              }}
            >
              {data?.farm_name}
            </td>
          </tr>

          <tr className="">
            <td className="text-[#585858] p-1">{t("Region Address")}</td>
            <td
              className="truncate"
              style={{
                fontFamily: isArabic(data?.address)
                  ? "Almarai"
                  : "Baloo-regular",
              }}
            >
              {data?.address?.substring?.(0, 26)}
            </td>
          </tr>

          <tr className="">
            <td className="text-[#585858] p-1">{t("#Farms")}</td>
            <td>{data?.fields_number}</td>
          </tr>

          {data?.farming_type === "tree" && (
            <tr className="">
              <td className="text-[#585858] p-1">{t("#Registered Trees")}</td>
              <td>{data?.trees ?? "-"}</td>
            </tr>
          )}

          <tr className="">
            <td className="text-[#585858] p-1">{t("#QR Coded Trees")}</td>
            <td>{data?.qr_coded_trees ?? "-"}</td>
          </tr>

          <tr className="">
            <td className="text-[#585858] p-1">
              {t("#RPW Dry Pheromone Trap")}
            </td>
            <td>{data?.dry_pheromone_traps}</td>
          </tr>

          <tr className="">
            <td className="text-[#585858] p-1">
              {t("#Borers Electric Dry Light Trap")}
            </td>
            <td>{data?.electric_dry_traps}</td>
          </tr>

          <tr className="">
            <td className="text-[#585858] p-1">
              {t("#Humira Delta Pheromone Trap")}
            </td>
            <td>{data?.humira_delta_traps}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default FarmCard;
