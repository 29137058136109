import {
  UPDATE_FORM,
  READ_USER_DETAILS,
  CLEAR_USER_DETAILS,
  START_USER_DETAILS_RELOAD,
  FINISHED_USER_DETAILS_RELOAD,
  USER_DETAILS_ERROR,
  CLEAR_USER_FORM,
  READ_USERS,
} from "../types/users";

const initialState = {
  userDetails: {
    honorofics: "",
    name: "",
    education: "",
    designation: "",
    userRole: "",
    farming_responsibility: [],
    non_farming_responsibility: [],
    email: "",
    contact: "",
    whatsapp: "",
    username: "",
    password: "",
    salary: "",
    start_date: "",
    last_date: "",
    user_type: "",
    policy_template_id: "",
    currentInService: "",
    farm_role: "",
  },
  profile_image: "",
  supervisory: {
    farms: "",
  },
  farmDetails: [],
  users: [],
  rowData: {},
  errors: [],
  loading: false,
};

export default function usersReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_FORM:
      return {
        ...state,
        [payload.key]: payload.value,
      };
    case READ_USER_DETAILS:
      return {
        ...state,
        farmDetails: payload,
      };
    case READ_USERS:
      return {
        ...state,
        users: payload.results,
      };
    case CLEAR_USER_FORM:
      return initialState;
    case START_USER_DETAILS_RELOAD:
      return {
        ...state,
        loading: true,
      };
    case FINISHED_USER_DETAILS_RELOAD:
      return {
        ...state,
        loading: false,
      };
    case USER_DETAILS_ERROR:
      return {
        ...state,
        errors: [...state.errors, payload],
      };
    default:
      return state;
  }
}
