import { useRouter } from "next/router";

function ErrorFallback({}) {
  const router = useRouter();

  return (
    <div className="w-full" role="alert ">
      <div className="">
        <div className="flex items-center justify-center w-9/12 min-h-screen py-16 m-auto">
          <div className="pb-8 overflow-hidden bg-white shadow sm:rounded-lg">
            <div className="pt-8 text-center border-t border-gray-200">
              <h1 className="font-bold text-9xl text-old-rose ">Error</h1>
              <h1 className="py-8 text-2xl font-medium md:text-4xl lg:text-6xl">
                Something Went Wrong
              </h1>
              <p className="px-12 pb-8 text-lg font-medium lg:text-2xl">
                We're sorry, but something went wrong. Please try again later.
              </p>
              <button
                onClick={() => router.reload()}
                className="px-6 py-3 mr-6 font-semibold text-white rounded-md bg-main bg-opacity-95 hover:bg-opacity-100 "
              >
                Back to home page
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ErrorFallback;
