import { SET_REPORT_DATA } from "../types/report";
import { authServices } from "services/auth.services";

const initialState = {
  fromDate: "",
  toDate: "",
};
export default function reportDataReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_REPORT_DATA:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
}
