import {
  CLEAR_PIVOT_FIELD,
  CLEAR_PIVOT_GEO_FIELD,
  READ_PIVOT_GEO_FIELDS,
  READ_PIVOT_ONE_FIELD,
  SET_PIVOT_FIELD_DATA,
  READ_PIVOT_FIELDS,
  READ_PIVOT_ACTIVITY_LOG,
  CREATE_PIVOT_FIELD,
  READ_CLUSTERS_PIVOT_FIELD_DETAILS,
  READ_PIVOT_CROP_FIELD_DETAILS,
  START_PIVOT_FIELDS_RELOAD,
  FINISHED_PIVOT_FIELDS_RELOAD,
  PIVOT_FIELD_ERROR,
  READ_PIVOT_CROP_FIELDS_ON_MAP,
  READ_ONE_PIVOT_CROP_FIELD,
  READ_PIVOT_CROP_FIELDS,
  READ_PIVOT_GEO_CROP_FIELDS,
  READ_IRR_NEXT_DAY_CROP_FIELDS,
  READ_WATER_CROP_CHART,
  READ_CROP_FLASK_CHART_DATA,
  READ_PIVOT_IRRIGATION_DETAILS,
  READ_VARIETIES,
  READ_CLUSTERS_PIVOT_FIELD,
  READ_ONE_CLUSTER,
  READ_DETAILS_CLUSTER_CHART,
  READ_CLUSTER_DETAILS_ACTIVITIES,
  UPDATE_PIVOT_FIELD,
  CLEAR_PIVOT_CROP_FIELD,
  CLEAR_PIVOT_GEO_CROP_FIELD,
  READ_CROP_FLASK_EVAPOTRANSPIRATION_CHART_DATA,
  READ_WATER_CROP_EVAPOTRANSPIRATION_CHART,
  READ_WATER_CROP_RERERENCE_CHART,
  CLEAR_CROP_FLASK_CHART_DATA,
  READ_IRR_LAYERS_CROP_FIELDS,
  READ_IRR_AQWA_LAYER_CROP_FIELDS,
  READ_CROP_FIELDS_LOGS,
  READ_CLUSTERS_PIVOT_FIELD_WITH_FILTER,
  READ_CROP_FIELDS_LOCATIONS_FOR_ADMINS,
  CLEAR_CROP_FIELDS_LOCATIONS_FOR_ADMINS,
} from "../../types/pivot/fields";
const initialState = {
  fields: [], // read pivot fields
  field: {}, // for one pivot field
  crop_fields: [], // for getting crop fields for a specifc farm or all crop fields
  crop_field: {}, // get one crop field by ID
  geo_fields: [], // for geo pivot fields
  geo_field: {}, // for geo one pivot field
  geo_crops_field_on_map: [], //  geo crops for a specific field variety
  geo_farm_crops_fields: [], // get geo crop fields for a specific farm with variety
  irrigation_next_day_crops_fields: [], // get geo crop fields for a specific farm with variety
  one_geo_crop_field: {}, //  one geo crop for one specific field
  pivotCropFieldDetails: [], // crop fields details for a specifc field
  clusterPivotFieldDetails: [], // cluster details for a speicifc pivot field
  cluster_pivot_fields: [], // cluster details for a speicifc pivot field
  geo_cluster_pivot_field: {}, // cluster details for a speicifc pivot field
  pivotActivityLog: [], // activities for pivot fields
  crop_water_chart_log: [], // data forc
  crop_flask_chart_log: [],
  crop_water_evapotranspiration_chart_log: [], // data forc
  crop_flask_evapotranspiration_chart_log: [],
  crop_water_Reference_chart_log: [],
  pivot_irrigation_details: [],
  clusters: [],
  cluster: {},
  cluster_details_chart: [],
  cluster_details_activities: [],
  varieties: [],
  loading: false,
  readable: false,
  errors: [],
  irrigation_layers_crops_fields: [],
  irrigation_aqwa_layer_crops_fields: [],
  crop_field_logs: [],
  clusterCategory: 0,
  crop_fields_location: [],
  crop_field_location: {},
};
export default function cropFieldsReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_PIVOT_FIELD_DATA:
      return {
        ...state,
        [payload.key]: payload.value,
      };
    case READ_PIVOT_FIELDS: // reading pivot fields
      return {
        ...state,
        fields: payload.results,
        readable: true,
      };
    case UPDATE_PIVOT_FIELD:
      return {
        ...state,
        fields: [
          ...state.fields.map((field) =>
            field.id === payload.field.id ? payload.field : field
          ),
        ],
      };
    case READ_ONE_CLUSTER: // reading pivot fields
      return {
        ...state,
        cluster: payload,
      };
    case READ_VARIETIES: // reading pivot fields
      return {
        ...state,
        varieties: payload.results,
      };
    case READ_DETAILS_CLUSTER_CHART: // reading pivot fields
      return {
        ...state,
        cluster_details_chart: payload,
      };
    case READ_CLUSTER_DETAILS_ACTIVITIES: // reading pivot fields
      return {
        ...state,
        cluster_details_activities: payload,
      };
    case READ_PIVOT_ONE_FIELD: // read one pivot field
      return {
        ...state,
        field: payload,
      };
    case READ_PIVOT_CROP_FIELDS: // read all  crops for a field  with query
      return {
        ...state,
        crop_fields: payload.results,
      };
    case READ_CLUSTERS_PIVOT_FIELD: // read all  crops for a field  with query
      return {
        ...state,
        cluster_pivot_fields: payload,
      };

    case READ_ONE_PIVOT_CROP_FIELD: // read one  crop field by crop id
      return {
        ...state,
        crop_field: payload,
        readable: true,
      };
    //////////////////
    case READ_PIVOT_GEO_FIELDS: // read geo fields using farm ID
      return {
        ...state,
        geo_fields: payload,
      };
    case READ_PIVOT_GEO_CROP_FIELDS: // read geo crops for a spicifc farm
      return {
        ...state,
        geo_farm_crops_fields: payload,
      };
    case READ_IRR_NEXT_DAY_CROP_FIELDS: // read geo crops for a spicifc farm
      return {
        ...state,
        irrigation_next_day_crops_fields: payload.data,
      };
    case READ_IRR_LAYERS_CROP_FIELDS:
      return {
        ...state,
        irrigation_layers_crops_fields: payload,
      };
    case READ_IRR_AQWA_LAYER_CROP_FIELDS:
      return {
        ...state,
        irrigation_aqwa_layer_crops_fields: payload,
      };

    case READ_PIVOT_CROP_FIELDS_ON_MAP: // read geo crops for a spicifc field using field ID
      return { ...state, geo_crops_field_on_map: payload ?? [] };
    case READ_PIVOT_IRRIGATION_DETAILS: // read geo crops for a spicifc field using field ID
      return { ...state, pivot_irrigation_details: payload ?? [] };
    case READ_PIVOT_CROP_FIELD_DETAILS:
      return {
        ...state,
        pivotCropFieldDetails: payload?.results ?? [],
      };
    case READ_CLUSTERS_PIVOT_FIELD_DETAILS:
      return {
        ...state,
        clusterPivotFieldDetails: payload ?? [],
      };

    case READ_PIVOT_ACTIVITY_LOG:
      return {
        ...state,
        pivotActivityLog: payload,
      };
    case READ_WATER_CROP_CHART:
      return {
        ...state,
        crop_water_chart_log: payload,
      };
    case READ_CROP_FLASK_EVAPOTRANSPIRATION_CHART_DATA:
      return {
        ...state,
        crop_water_evapotranspiration_chart_log: payload,
      };
    case READ_WATER_CROP_EVAPOTRANSPIRATION_CHART:
      return {
        ...state,
        crop_flask_evapotranspiration_chart_log: payload,
      };
    case READ_WATER_CROP_RERERENCE_CHART:
      return {
        ...state,
        crop_water_Reference_chart_log: payload,
      };
    case READ_CROP_FLASK_CHART_DATA:
      return {
        ...state,
        crop_flask_chart_log: payload,
      };
    case CREATE_PIVOT_FIELD:
      return {
        ...state,
        fields: [payload, ...state.fields],
        field: payload,
      };

    case CLEAR_PIVOT_FIELD:
      return {
        ...state,
        field: {},
      };
    case CLEAR_PIVOT_GEO_FIELD:
      return {
        ...state,
        geo_field: {},
      };
    case START_PIVOT_FIELDS_RELOAD:
      return {
        ...state,
        loading: true,
      };
    case FINISHED_PIVOT_FIELDS_RELOAD:
      return {
        ...state,
        loading: false,
      };
    case PIVOT_FIELD_ERROR:
      return {
        ...state,
        errors: [...state.errors, payload],
      };
    case CLEAR_PIVOT_CROP_FIELD:
      return {
        ...state,
        crop_field: {},
      };
    case CLEAR_PIVOT_GEO_CROP_FIELD:
      return {
        ...state,
        one_geo_crop_field: {},
      };
    case CLEAR_CROP_FLASK_CHART_DATA:
      return {
        ...state,
        crop_water_chart_log: [],
        crop_flask_chart_log: [],
      };
    case READ_CROP_FIELDS_LOGS:
      return {
        ...state,
        crop_field_logs: payload,
      };
    case READ_CLUSTERS_PIVOT_FIELD_WITH_FILTER:
      return {
        ...state,
        cluster_pivot_fields: payload,
      };
    case READ_CROP_FIELDS_LOCATIONS_FOR_ADMINS:
      return {
        ...state,
        crop_fields_location: payload,
      };
    case CLEAR_CROP_FIELDS_LOCATIONS_FOR_ADMINS:
      return {
        ...state,
        crop_fields_location: [],
      };

    default:
      return state;
  }
}
