import Button from "@/components/common/form/button";
import messageLogo from "assets/svg/messageLogo.svg";
import Image from "next/image";
import { authServices } from "services/auth.services";
import { orgsService } from "services/settings/organization.service";

function SubmitButton({ data, actionCheck }) {
  return (
    <Button
      title="Ok"
      className="w-full py-2 -mt-5 font-bold text-white rounded-md xl:py-2 bg-main text-md "
      click={() => actionCheck(data)}
    />
  );
}

const Settings = ({ data, showModel, setShowModel }) => {
  const onSubmit = async (data) => {
    if (data?.action === "normal") {
      data.notification_status = false;
      let res = await orgsService.sendMessage(
        data,
        authServices?.userValue?.org_id
      );
      if (res) {
        setShowModel(false);
      } else {
        console.log(data);
      }
    }
  };

  const actionCheck = (data) => {
    if (data?.action === "normal") {
      return setShowModel(false);
    } else {
      authServices.logout();
    }
  };

  return (
    <div
      style={{
        zIndex: 1000000000,
      }}
      className={`${
        showModel ? "" : "hidden"
      } fixed top-0 bottom-0 left-0 right-0 w-full h-full bg-model overflow-hidden`}
    >
      <div
        className={`relative flex items-center justify-center w-full h-full `}
      >
        <div
          className={`fixed  bg-white overflow-scroll rounded-md  2xl:h-[80%] h-[100%] md:h-[90%] lg:h-[90%] sm:w-[80%] md:w-[60%] pt-5 px-10 lg:w-[50%] 2xl:w-[35%]`}
        >
          <div className="flex flex-col justify-between h-full">
            <div className="flex flex-col gap-2">
              <div className="font-bold text-main text-[20px]">
                Notification
              </div>
              <div className="flex justify-center">
                <div className="relative xl:w-[70%]  xl:h-[70%] w-[50%] h-[50%]">
                  <Image className={""} src={messageLogo} alt="img" />
                </div>
              </div>
              <div className="xl:text-[40px] text-[30px] font-bold text-center capitalize">
                Hello {" " + data?.honorofics + " " + data?.name}
              </div>
              <div className="text-[#585757] xl:text-[26px] text-[20px] text-center -mt-2">
                {data?.message}
              </div>
            </div>
            <div className="">
              {data?.action === "normal" ? (
                <Button
                  title="Dont show again"
                  click={() => onSubmit(data)}
                  className="w-full py-2  font-bold border-[#727171] border text-[#727171] rounded-md  text-md "
                />
              ) : (
                <div></div>
              )}
              <SubmitButton data={data} actionCheck={actionCheck} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
