export const CLEAR_TRAPS_ON_MAP = "CLEAR_TRAPS_ON_MAP";
export const READ_ONE_TRAPS_ON_MAP = "READ_ONE_TRAPS_ON_MAP";
export const SET_TRAPS_ON_MAP_DATA = "SET_TRAPS_ON_MAP_DATA";
export const READ_TRAPS_ON_MAPS = "READ_TRAPS_ON_MAPS";
export const START_TRAPS_RELOAD = "START_TRAPS_RELOAD";
export const FINISHED_TRAPS_RELOAD = "FINISHED_TRAPS_RELOAD";
export const TRAPS_ON_MAP_ERROR = "TRAPS_ON_MAP_ERROR";
export const READ_ONE_TRAP = "READ_ONE_TRAP";
export const READ_TRAPS = "READ_TRAPS";
export const READ_TRAPS_CHARTS = "READ_TRAPS_CHARTS";
export const READ_TRAPS_ACTIVITIES = "READ_TRAPS_ACTIVITIES";
export const READ_CATCHES_TRAPS_ON_MAP = "READ_CATCHES_TRAPS_ON_MAP";
