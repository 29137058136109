import {
  CLEAR_PIVOT_PEST_DISEASE,
  READ_ONE_PIVOT_PEST_DISEASE,
  SET_PIVOT_PEST_DISEASE_DATA,
  READ_PIVOT_PEST_DISEASES,
  START_PIVOT_PEST_DISEASES_RELOAD,
  FINISHED_PIVOT_PEST_DISEASES_RELOAD,
  PIVOT_PEST_DISEASE_ERROR,
} from "../../types/pivot/pest-disease";
const initialState = {
  pest_disease_fields: [], // read pivot pest_disease_fields
  pest_disease_field: {}, // for one pivot pest_disease_field
  loading: false,
  readable: false,
  errors: [],
};
export default function pest_disease_fieldsReducer(
  state = initialState,
  action
) {
  const { type, payload } = action;
  switch (type) {
    case SET_PIVOT_PEST_DISEASE_DATA:
      return {
        ...state,
        [payload.key]: payload.value,
      };
    case READ_PIVOT_PEST_DISEASES: // reading pivot pest_disease_fields
      return {
        ...state,
        pest_disease_fields: payload,
        readable: true,
      };
    case READ_ONE_PIVOT_PEST_DISEASE: // read one pivot pest_disease_field
      return {
        ...state,
        pest_disease_field: payload,
      };
    case CLEAR_PIVOT_PEST_DISEASE:
      return {
        ...state,
        pest_disease_field: {},
      };

    case START_PIVOT_PEST_DISEASES_RELOAD:
      return {
        ...state,
        loading: true,
      };
    case FINISHED_PIVOT_PEST_DISEASES_RELOAD:
      return {
        ...state,
        loading: false,
      };
    case PIVOT_PEST_DISEASE_ERROR:
      return {
        ...state,
        errors: [...state.errors, payload],
      };

    default:
      return state;
  }
}
