import { get } from "@/redux/crud/get";
import { post } from "../crud/post";
import { patch } from "../crud/patch";
import {
  SET_FARM_DATA,
  SET_FORM_FARM_STEP,
  CLEAR_GEO_FARM,
  CLEAR_FARM,
  SET_FARM_TYPE,
  CLEAR_FARMS,
} from "../types/farms";

export const setFarmData = (payload) => (dispatch) => {
  dispatch({ type: SET_FARM_DATA, payload });
};

export const readOneFarm = (id) =>
  get({
    url: process.env.NEXT_PUBLIC_BASE_URL + "farms/" + id + "/",
    title: "Farm",
  });

export const creatFarm = (formData) =>
  post({
    url: process.env.NEXT_PUBLIC_BASE_URL + "farms/",
    title: "Farm",
    formData,
  });

export const updateFarm = (formData) =>
  patch({
    url: process.env.NEXT_PUBLIC_BASE_URL + "farms/" + formData.id + "/",
    title: "Farm",
    formData,
  });

export const readFarms = () =>
  get({
    url: process.env.NEXT_PUBLIC_BASE_URL + "farms/",
    title: "Farm",
  });

export const setFarmFormStep = (payload) => (dispatch) => {
  dispatch({ type: SET_FORM_FARM_STEP, payload });
};

export const clearFarm = () => (dispatch) => {
  dispatch({ type: CLEAR_FARM });
};
export const clearFarms = () => (dispatch) => {
  dispatch({ type: CLEAR_FARMS });
};
export const clearGeoFarm = () => (dispatch) => {
  dispatch({ type: CLEAR_GEO_FARM });
};

export const setCurrentType = (payload) => (dispatch) => {
  dispatch({ type: SET_FARM_TYPE, payload });
};
