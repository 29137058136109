import {
  CLEAR_GEO_SOIL_SAMPLES,
  READ_GEO_SOIL_SAMPLES,
  START_SOIL_SAMPLES_RELOAD,
  FINISHED_SOIL_SAMPLES_RELOAD,
  SOIL_SAMPLES_ERROR,
  SET_SOIL_SAMPLES_DATA,
  READ_SOIL_SAMPLE_DETAILS,
} from "../../types/pivot/soilSamples";
const initialState = {
  soilSamples: [],
  soilSamplesDetails: [],
  loading: false,
  readable: false,

  errors: [],
};
export default function soilSamplesReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case READ_GEO_SOIL_SAMPLES:
      return {
        ...state,
        soilSamples: payload ?? [],
        readable: true,
      };

    case SET_SOIL_SAMPLES_DATA:
      return {
        ...state,
        [payload.key]: payload.value,
      };

    case CLEAR_GEO_SOIL_SAMPLES:
      return {
        ...state,
        soilSamples: [],
      };
    case START_SOIL_SAMPLES_RELOAD:
      return {
        ...state,
        loading: true,
      };
    case FINISHED_SOIL_SAMPLES_RELOAD:
      return {
        ...state,
        loading: false,
      };
    case SOIL_SAMPLES_ERROR:
      return {
        ...state,
      };
    case READ_SOIL_SAMPLE_DETAILS:
      return {
        ...state,
        soilSamplesDetails: payload,
      };
    default:
      return state;
  }
}
