export const SET_FIELD_DATA = "SET_FIELD_DATA";
export const READ_GEO_FIELDS = "READ_GEO_FIELDS";
export const READ_FIELDS = "READ_FIELDS";
export const READ_ONE_FIELD = "READ_ONE_FIELD";
export const CREATE_FIELD = "CREATE_FIELD";
export const UPDATE_FIELD = "UPDATE_FIELD";
export const DELETE_FIELD = "DELETE_FIELD";
export const SEARCH_FIELD_FILTER = "SEARCH_FIELD_FILTER";
export const FIELD_ERROR = "FIELD_ERROR";
export const CLEAR_FIELD = "CLEAR_FIELD";
export const START_FIELDS_RELOAD = "START_FIELDS_RELOAD";
export const FINISHED_FIELDS_RELOAD = "FINISHED_FIELDS_RELOAD";
export const CLEAR_GEO_FIELD = "CLEAR_GEO_FIELD";
export const READ_IRRIGATION_FIELDS = "READ_IRRIGATION_FIELDS";
export const CLEAR_IRRIGATION_FIELDS = "CLEAR_IRRIGATION_FIELDS";
export const FIELDS_PAGINATION = "FIELDS_PAGINATION";
export const CLEAR_FIELDS = "CLEAR_FIELDS";
export const CLEAR_GEO_FIELDS = "CLEAR_GEO_FIELDS";
export const READ_WATER_TREE_CHART = "READ_WATER_TREE_CHART";
export const READ_IRR_LAYERS_TREE_FIELDS = "READ_IRR_LAYERS_TREE_FIELDS";
export const CLEAR_WATER_TREE_CHART = "CLEAR_WATER_TREE_CHART";
export const READ_ONE_FIELD_DETAILS = "READ_ONE_FIELD_DETAILS";
export const READ_TREE_FIELDS = "READ_TREE_FIELDS";
export const READ_LOGS = "READ_LOGS";
export const READ_IRRIGATION_FAULTY_DRIPS = "READ_IRRIGATION_FAULTY_DRIPS";
export const READ_ADVANCED_IRRIGATION_FIELD = "READ_ADVANCED_IRRIGATION_FIELD";
export const READ_FIELD_LEGEND = "READ_FIELD_LEGEND";
