import {
  SET_WATERNETWORK_DATA,
  READ_WATERNETWORKS,
  READ_ONE_WATERNETWORK,
  CREATE_WATERNETWORK,
  UPDATE_WATERNETWORK,
  DELETE_WATERNETWORK,
  CLEAR_WATERNETWORK,
  START_WATERNETWORKS_RELOAD,
  FINISHED_WATERNETWORKS_RELOAD,
  WATER_NETWORKERROR,
  READ_WATERNETWORK_ITEMS,
} from "../../types/tree/water-networks";

const initialState = {
  water_network_items: [],
  water_networks: [],
  water_network: {},
  geo_water_network: {},
  form_water_network: {},
  loading: false,
  readable: false,
  errors: [],
};
export default function waterNetworksReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_WATERNETWORK_DATA:
      return {
        ...state,
        [payload.key]: {
          ...state[payload.key],
          ...payload.value,
        },
      };

    case READ_WATERNETWORK_ITEMS:
      return { ...state, water_network_items: [...payload] };
    case READ_WATERNETWORKS:
      return {
        ...state,
        water_networks: [...payload.results],
        readable: true,
      };
    case READ_ONE_WATERNETWORK:
      return {
        ...state,
        water_network: { ...payload },
      };

    case CREATE_WATERNETWORK:
      return {
        ...state,
        water_networks: [payload, ...state.water_networks],
        water_network: payload,
      };
    case UPDATE_WATERNETWORK:
      return {
        ...state,
        water_networks: [
          ...state.water_networks.map((water_network) =>
            water_network.id === payload.water_network.id
              ? payload.water_network
              : water_network
          ),
        ],
      };
    case DELETE_WATERNETWORK:
      return {
        ...state,
        water_networks: [
          ...state.water_networks.filter(
            (water_network) => water_network.id !== payload.water_network.id
          ),
        ],
      };

    case CLEAR_WATERNETWORK:
      return {
        ...state,
        water_network: {},
      };

    case START_WATERNETWORKS_RELOAD:
      return {
        ...state,
        loading: true,
      };
    case FINISHED_WATERNETWORKS_RELOAD:
      return {
        ...state,
        loading: false,
      };
    case WATER_NETWORKERROR:
      return {
        ...state,
        errors: [...state.errors, payload],
      };

    default:
      return state;
  }
}
