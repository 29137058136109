export const SET_ASSETS_DATA = "SET_TREE_DATA";
export const READ_GEO_ASSETS = "READ_ASSETS";
export const CLEAR_GEO_ASSETS = "CLEAR_ASSETS";
export const START_ASSETS_RELOAD = "START_TREES_RELOAD";
export const FINISHED_ASSETS_RELOAD = "FINISHED_TREES_RELOAD";
export const ASSETS_ERROR = "TREE_ERROR";
export const READ_ONE_MACHINIARY = "READ_ONE_MACHINIARY";
export const READ_ASSETS_ACTIVITIES = "READ_ASSETS_ACTIVITIES";
export const ASSETS_CHART = "ASSETS_CHART";
export const READ_OPERATIONAL_HOURS = "READ_OPERATIONAL_HOURS";
export const READ_MACHINERIES_BY_CATEGORY = "READ_MACHINERIES_BY_CATEGORY";
export const READ_ONE_WATER_ITEM = "READ_ONE_WATER_ITEM";
export const READ_VALVE_ACTIVITIES = "READ_VALVE_ACTIVITIES";
export const READ_VALVE_CHART = "READ_VALVE_CHART";
export const READ_FLOW_METER_ACTIVITIES = "READ_FLOW_METER_ACTIVITIES";
export const READ_FLOW_METER_CHART = "READ_FLOW_METER_CHART";
export const READ_WATER_ITEMS_BY_FIELD_ID = "READ_WATER_ITEMS_BY_FIELD_ID";
export const READ_ONE_VALVE_ITEM = "READ_ONE_VALVE_ITEM";
export const CALCULATE_VALVE_BBOX = "CALCULATE_VALVE_BBOX";
