import {
  CLEAR_GEO_ASSETS,
  READ_GEO_ASSETS,
  START_ASSETS_RELOAD,
  FINISHED_ASSETS_RELOAD,
  ASSETS_ERROR,
  READ_ONE_MACHINIARY,
  READ_ASSETS_ACTIVITIES,
  ASSETS_CHART,
  READ_MACHINERIES_BY_CATEGORY,
  SET_ASSETS_DATA,
  READ_ONE_WATER_ITEM,
  READ_OPERATIONAL_HOURS,
  READ_VALVE_ACTIVITIES,
  READ_VALVE_CHART,
  READ_FLOW_METER_ACTIVITIES,
  READ_FLOW_METER_CHART,
  READ_WATER_ITEMS_BY_FIELD_ID,
  READ_ONE_VALVE_ITEM,
} from "../types/assets";
const initialState = {
  assets: [],
  loading: false,
  readable: false,
  machiniay: {},
  oneWateritem: {},
  machineriesByCategory: [],
  assets_activities: [],
  assets_chart: [],
  machineryOperationalhours: [],
  assetsValveChart: [],
  assetsValveActivities: [],
  assetsFlowMeterChart: [],
  assetsFlowMeterActivities: [],
  waterItemWithFieldId: [],
  errors: [],
  oneValveItem: {},
};
export default function assetsReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case READ_WATER_ITEMS_BY_FIELD_ID:
      return {
        ...state,
        waterItemWithFieldId: payload.results ?? [],
      };
    case READ_ONE_VALVE_ITEM:
      return {
        ...state,
        oneValveItem: payload ?? [],
        readable: true,
      };
    case READ_GEO_ASSETS:
      return {
        ...state,
        assets: payload ?? [],
        readable: true,
      };
    case READ_FLOW_METER_CHART:
      return {
        ...state,
        assetsFlowMeterChart: payload ?? [],
      };
    case READ_FLOW_METER_ACTIVITIES:
      return {
        ...state,
        assetsFlowMeterActivities: payload ?? [],
      };
    case READ_VALVE_CHART:
      return {
        ...state,
        assetsValveChart: payload ?? [],
      };
    case READ_VALVE_ACTIVITIES:
      return {
        ...state,
        assetsValveActivities: payload ?? [],
      };
    case READ_OPERATIONAL_HOURS:
      return {
        ...state,
        machineryOperationalhours: payload,
      };
    case READ_ONE_WATER_ITEM:
      return {
        ...state,
        oneWateritem: payload,
      };
    case SET_ASSETS_DATA:
      return {
        ...state,
        [payload.key]: payload.value,
      };
    case READ_MACHINERIES_BY_CATEGORY:
      return {
        ...state,
        machineriesByCategory: payload.results,
      };
    case READ_ONE_MACHINIARY:
      return {
        ...state,
        machiniay: payload,
      };
    case READ_ASSETS_ACTIVITIES:
      return {
        ...state,
        assets_activities: payload,
      };
    case ASSETS_CHART:
      return {
        ...state,
        assets_chart: payload,
      };
    case CLEAR_GEO_ASSETS:
      return {
        ...state,
        assets: [],
      };
    case START_ASSETS_RELOAD:
      return {
        ...state,
        loading: true,
      };
    case FINISHED_ASSETS_RELOAD:
      return {
        ...state,
        loading: false,
      };
    case ASSETS_ERROR:
      return {
        ...state,
      };
    default:
      return state;
  }
}
