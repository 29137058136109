import { REPORT_REFRESH_MAP, SET_REPORT_DATA } from "../../types/reports/index";
import _ from "lodash";
const initialState = {
  refreshReportMap: true,
  imageDate: "",
};
export default function reportReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_REPORT_DATA:
      return {
        ...state,
        [payload.key]: payload.value,
      };
    case REPORT_REFRESH_MAP:
      return {
        ...state,
        refreshReportMap: payload,
      };

    default:
      return state;
  }
}
