import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// type Alert = {
//   active: boolean;
//   message: string;
// };
type PropsType = {
  title: string;
  loading?: boolean;
  disabled?: boolean;
  style?: object;
  events?: any[];
  click?: any;
  className?: string;
  icon?: any;
  isAlert?: boolean;
  formState?: any;
  type?: any;
  showAlert?: boolean;
};
const Button: React.FC<PropsType> = ({
  title,
  loading,
  disabled,
  style,
  click,
  events,
  className,
  icon,
  formState,
  isAlert,
  type = "submit",

  showAlert = false,
}) => {
  const [Alert, setAlert] = useState({
    active: false,
    message: "",
    color: "text-main",
  });
  const { i18n } = useTranslation();

  if (formState) {
    useEffect(() => {
      if (formState.isSubmitted) {
        if (formState.isSubmitSuccessful) {
          setAlert({
            active: true,
            message: "Request Submit Successfully",
            color: "text-main",
          });
        } else {
          setAlert({
            active: true,
            message: "Something went wrong!!",
            color: "text-red-800",
          });
        }
      }
    }, [formState.isSubmitted]);
    useEffect(() => {
      let timeout;
      if (Alert.active === true) {
        timeout = setTimeout(() => {
          setAlert({
            active: false,
            message: "",
            color: "text-main",
          });
        }, 3000);
      }
      return () => {
        clearTimeout(timeout);
      };
    }, [Alert.active]);
  }
  return (
    <div className="py-3">
      {showAlert && (
        <div className={`${Alert.color} text-lg text-center w-full`}>
          {Alert.message}
        </div>
      )}
      <button
        {...events}
        type={type}
        style={{
          fontFamily: `${i18n.language === "en" ? "Baloo-regular" : "Almarai"}`,
        }}
        disabled={(disabled || loading) ?? false}
        className={`${className}   bg-opacity-95 hover:bg-opacity-100 outline-none focus:outline-none shadow hover:shadow-md disabled:bg-gray-500 disabled:cursor-not-allowed   capitalize ${
          icon ? `flex justify-around items-center gap-2` : ``
        } `}
        onClick={click ? click : () => {}}
      >
        {icon && !loading ? icon() : ""}
        {loading ? (
          <div className="flex items-center justify-center w-full h-full px-10 ">
            <div className="w-5 h-5 border-4 rounded-full border-t-main animate-spin"></div>
          </div>
        ) : (
          title
        )}
      </button>
    </div>
  );
};

export default Button;
