export const SET_TREE_DATA = "SET_TREE_DATA";
export const READ_TREES_WITH_FILTER = "READ_TREES_WITH_FILTER";
export const READ_GEO_TREES = "READ_GEO_TREES";
export const READ_TREES = "READ_TREES";
export const READ_ONE_TREE = "READ_ONE_TREE";
export const CREATE_TREE = "CREATE_TREE";
export const UPDATE_TREE = "UPDATE_TREE";
export const INC_GEO_TREES = "INC_GEO_TREES";
export const DELETE_TREE = "DELETE_TREE";
export const SEARCH_TREES = "SEARCH_TREES";
export const TREE_ERROR = "TREE_ERROR";
export const CLEAR_TREE = "CLEAR_TREE";
export const START_TREES_RELOAD = "START_TREES_RELOAD";
export const FINISHED_TREES_RELOAD = "FINISHED_TREES_RELOAD";
export const CLEAR_TREES = "CLEAR_TREES";
export const CLEAR_GEO_TREES = "CLEAR_GEO_TREES";
export const READ_ALL_INFECTED_TREE_DATA = "READ_ALL_INFECTED_TREE_DATA";
export const READ_FIELD_INFECTED_TREE_DATA = "READ_FIELD_INFECTED_TREE_DATA";
export const READ_TREES_CREATION_STATUS = "READ_TREES_CREATION_STATUS";
export const READ_INFECTIONS_GEO_TREES = "READ_INFECTIONS_GEO_TREES";
