import React from "react";

function loaderV2() {
  return (
    <div className="flex items-center justify-center w-full h-full bg-[#F7F7F7]  ">
      <div className=" loader"></div>
    </div>
  );
}

export default loaderV2;
