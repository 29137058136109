import axios from "axios";
import { setAlert } from "../actions/alert";
import { v4 as uuidv4 } from "uuid";
import Cookie from "js-cookie";

const startInsideReload = (title) => (dispatch) => {
  dispatch({ type: `START_${title.toUpperCase()}S_RELOAD` });
};

const finishedInsideReload = (title) => (dispatch) => {
  dispatch({ type: `FINISHED_${title.toUpperCase()}S_RELOAD` });
};
type Get = {
  startReload?: any;
  finishedReload?: any;
  headers?: any;
  title?: any;
  successType?: any;
  errorType?: any;
  url: any;
  noReload?: boolean;
  successData?: any;
};
export const get = ({
  headers = {
    Authorization: `Bearer ${Cookie.get("token")}`,
  },
  title,
  successType = `READ_${title.toUpperCase()}S`,
  errorType = `${title.toUpperCase()}_ERROR`,
  startReload = startInsideReload,
  finishedReload = finishedInsideReload,
  url,
  noReload = false,
  successData = {},
}: Get) => {
  return async (dispatch) => {
    const onSuccess = (success) => {
      dispatch({
        type: successType,
        payload: success.data,
      });

      /*  dispatch(
        setAlert({
          message: `${title}s successfully fetched`,
          status: "success",
          priority: "low",
        })
      ); */
      !noReload && dispatch(finishedReload(title));
      // console.log({ success });
      return success;
    };
    const onError = (error) => {
      console.error({ errorType: error });
      dispatch({
        type: errorType,
        payload: {
          // ...error.response.data,
          type: successType,
          date: new Date(),
          id: uuidv4(),
        },
      });

      // if(error.response.data ){

      // }\
      if (!error.response) {
        /*  dispatch(
          setAlert({
            message: `error: Something Wrong!`,
            status: "error",
            priority: "high",
          })
        ); */
        !noReload && dispatch(finishedReload(title));

        return error;
      } else if (
        (typeof error.response.data === "string" &&
          error.response.data.includes("<!DOCTYPE html>")) ||
        (typeof error.response.data === "string" &&
          error.response.data.includes("<html>"))
      ) {
        /* dispatch(
          setAlert({
            message: `error: Something Wrong!`,
            status: "error",
            priority: "high",
          })
        ); */
        !noReload && dispatch(finishedReload(title));

        return error;
      } else {
        Object.entries(error.response.data)
          .map(([k, v]) => {
            return { [k]: Array.isArray(v) ? v[0] : v };
          })
          .map((alert) => {
            /*             dispatch(
              setAlert({
                message: Object.keys(alert) + ": " + Object.values(alert),
                status: "error",
                priority: "high",
              })
            ); */
          });

        !noReload && dispatch(finishedReload(title));

        return error;
      }
    };
    try {
      !noReload && dispatch(startReload(title));
      const success = await axios.get(url, {
        headers: headers,
      });

      return onSuccess(success);
    } catch (error) {
      return onError(error);
    }
  };
};
