export const SET_FARM_DATA = "SET_FARM_DATA";
export const READ_FARMS = "READ_FARMS";
export const READ_ONE_FARM = "READ_ONE_FARM";
export const CREATE_FARM = "CREATE_FARM";
export const UPDATE_FARM = "UPDATE_FARM";
export const DELETE_FARM = "DELETE_FARM";
export const SEARCH_FARM_FILTER = "SEARCH_FARM_FILTER";
export const FARM_ERROR = "FARM_ERROR";
export const CLEAR_FARM = "CLEAR_FARM";
export const CLEAR_GEO_FARM = "CLEAR_GEO_FARM";
export const START_FARMS_RELOAD = "START_FARMS_RELOAD";
export const FINISHED_FARMS_RELOAD = "FINISHED_FARMS_RELOAD";
export const SET_FORM_FARM_STEP = "SET_FORM_FARM_STEP";
export const SET_FARM_TYPE = "SET_FARM_TYPE";
export const CLEAR_FARMS = "CLEAR_FARMS";
