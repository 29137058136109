export const SET_FIELD_DATA = "SET_FIELD_DATA";

export const READ_PIVOT_GEO_FIELDS = "READ_PIVOT_GEO_FIELDS";
export const READ_PIVOT_FIELDS = "READ_PIVOT_FIELDS";
export const READ_PIVOT_ONE_FIELD = "READ_PIVOT_ONE_FIELD";
export const CREATE_PIVOT_FIELD = "CREATE_PIVOT_FIELD";
export const UPDATE_PIVOT_FIELD = "UPDATE_PIVOT_FIELD";
export const DELETE_PIVOT_FIELD = "DELETE_PIVOT_FIELD";
export const SEARCH_PIVOT_FIELD_FILTER = "SEARCH_PIVOT_FIELD_FILTER";
export const PIVOT_FIELD_ERROR = "PIVOT_FIELD_ERROR";
export const CLEAR_PIVOT_FIELD = "CLEAR_PIVOT_FIELD";
export const START_PIVOT_FIELDS_RELOAD = "START_PIVOT_FIELDS_RELOAD";
export const FINISHED_PIVOT_FIELDS_RELOAD = "FINISHED_PIVOT_FIELDS_RELOAD";
export const CLEAR_PIVOT_GEO_FIELD = "CLEAR_PIVOT_GEO_FIELD";
export const READ_IRRIGATION_FIELDS = "READ_IRRIGATION_FIELDS";
export const CLEAR_IRRIGATION_FIELDS = "CLEAR_IRRIGATION_FIELDS";
export const SET_PIVOT_FIELD_DATA = "SET_PIVOT_FIELD_DATA";
export const READ_PIVOT_ACTIVITY_LOG = "READ_PIVOT_ACTIVITY_LOG";
export const READ_PIVOT_CROP_FIELD_DETAILS = "READ_PIVOT_CROP_FIELD_DETAILS";
export const READ_CLUSTERS_PIVOT_FIELD_DETAILS =
  "READ_CLUSTERS_PIVOT_FIELD_DETAILS";
export const READ_PIVOT_CROP_FIELDS_ON_MAP = "READ_PIVOT_CROP_FIELDS_ON_MAP";
export const READ_ONE_PIVOT_CROP_FIELD = "READ_ONE_PIVOT_CROP_FIELD";
export const READ_PIVOT_CROP_FIELDS = "READ_PIVOT_CROP_FIELDS";
export const READ_PIVOT_GEO_CROP_FIELDS = "READ_PIVOT_GEO_CROP_FIELDS";
export const READ_WATER_CROP_CHART = "READ_WATER_CROP_CHART";
export const READ_CROP_FLASK_CHART_DATA = "READ_CROP_FLASK_CHART_DADA";
export const READ_PIVOT_IRRIGATION_DETAILS = "READ_PIVOT_IRRIGATION_DETAILS";
export const READ_VARIETIES = "READ_VARIETIES";
export const READ_CLUSTERS_PIVOT_FIELD = "READ_CLUSTERS_PIVOT_FIELD";
export const READ_ONE_CLUSTER = "READ_ONE_CLUSTER";
export const READ_DETAILS_CLUSTER_CHART = "READ_DETAILS_CLUSTER_CHART";
export const READ_CLUSTER_DETAILS_ACTIVITIES =
  "READ_CLUSTER_DETAILS_ACTIVITIES";
export const READ_IRR_NEXT_DAY_CROP_FIELDS = "READ_IRR_NEXT_DAY_CROP_FIELDS";
export const CLEAR_PIVOT_CROP_FIELD = "CLEAR_PIVOT_CROP_FIELD";
export const CLEAR_PIVOT_GEO_CROP_FIELD = "CLEAR_PIVOT_GEO_CROP_FIELD";
export const READ_CROP_FLASK_EVAPOTRANSPIRATION_CHART_DATA =
  "READ_CROP_FLASK_EVAPOTRANSPIRATION_CHART_DATA";
export const READ_WATER_CROP_EVAPOTRANSPIRATION_CHART =
  "READ_WATER_CROP_EVAPOTRANSPIRATION_CHART";
export const READ_WATER_CROP_RERERENCE_CHART =
  "READ_WATER_CROP_RERERENCE_CHART";
export const CLEAR_CROP_FLASK_CHART_DATA = "CLEAR_CROP_FLASK_CHART_DATA";
export const READ_IRR_LAYERS_CROP_FIELDS = "READ_IRR_LAYERS_CROP_FIELDS";
export const READ_IRR_AQWA_LAYER_CROP_FIELDS =
  "READ_IRR_AQWA_LAYER_CROP_FIELDS";
export const READ_CROP_FIELDS_LOGS = "READ_CROP_FIELDS_LOGS";
export const READ_CLUSTERS_PIVOT_FIELD_WITH_FILTER =
  "READ_CLUSTERS_PIVOT_FIELD_WITH_FILTER";
export const READ_CROP_FIELDS_LOCATIONS_FOR_ADMINS =
  "READ_CROP_FIELDS_LOCATIONS_FOR_ADMINS";
export const CLEAR_CROP_FIELDS_LOCATIONS_FOR_ADMINS =
  "CLEAR_CROP_FIELDS_LOCATIONS_FOR_ADMINS";
export const CROP_FIELD_LOCATION = "CROP_FIELD_LOCATION";
