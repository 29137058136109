import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setNotAuthorizedAlertData } from "../../redux/actions/notAuthorizedAlert";

function notAuthorizedAlert() {
  const dispatch = useDispatch();
  const { showModal } = useSelector((state) => state.notAuthorizedAlertReducer);
  const { t } = useTranslation();
  return (
    <div
      style={{
        /*   fontFamily: `${i18n.language === "en" ? "Baloo-regular" : "Almarai"} `, */
        zIndex: 1000000000,
      }}
      className={`${
        showModal ? "" : "hidden"
      } fixed top-0 bottom-0 left-0 right-0 w-full h-full bg-model overflow-hidden`}
    >
      <div
        className={`relative flex items-center justify-center w-full h-full `}
      >
        <div className={`fixed  bg-white  rounded-lg  w-[344px]  `}>
          <div className="bg-[#BF5239] border-[#BF5239] border text-white rounded-t-lg xl:h-[93px] h-[80px] flex items-center justify-center xl:text-[36px] text-[30px]">
            {t("Alert!")}
          </div>
          <div className="text-[#3C3C3C] xl:text-[30px] text-[25px] px-4 font-semibold text-center xl:my-7 my-5 leading-none">
            This feature is assigned to the operation team.
          </div>
          <div className="flex items-center justify-center mb-7">
            <div
              onClick={() => {
                dispatch(
                  setNotAuthorizedAlertData({ key: "showModal", value: false })
                );
              }}
              className="bg-main bg-opacity-90 hover:bg-opacity-100 text-white rounded-lg flex items-center justify-center font-bold xl:text-[20px] text-[15px] cursor-pointer w-[128px] h-[35px] xl:h-[49px]"
            >
              {t("Ok")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default notAuthorizedAlert;
