import axios from "axios";
import useSWR, { mutate } from "swr";
import Cookies from "js-cookie";

export const useSwrGet = (apiKey, url, swrProps = {}) => {
  let config;
  if (Cookies.get("token")) {
    config = {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    };
  } else
    config = {
      headers: {},
    };
  const fetcher = () => axios.get(url, config).then((res) => res.data);
  const { data, error, mutate } = useSWR(apiKey, fetcher, {
    revalidateOnFocus: true,
    ...swrProps,
  });
  return {
    data,
    isLoading: !data && !error,
    isError: error,
    mutate,
  };
};
