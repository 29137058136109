import { useState } from "react";
import Plus from "assets/img/Plus.png";
import Button from "@/components/common/form/button";
import { useSwrGet } from "@/utils/use-swr-get";
import { useRouter } from "next/router";
import Loader from "components/common/loader.components";
import Image from "next/image";
import { setNotAuthorizedAlertData } from "@/redux/actions/notAuthorizedAlert";
import dynamic from "next/dynamic";
import { columns } from "tables-columns/farm.listing";
import Logo from "assets/svg/nakheelLogo.svg";

import {
  setCurrentType,
  setFarmData,
  clearFarm,
  clearGeoFarm,
  setFarmFormStep,
} from "@/redux/actions/farms";
import { navigationServices } from "services/navigation.service";

import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { setTableTabs, setTreeTab } from "@/redux/actions/nav";
import { useTranslation } from "react-i18next";
import { setAlert } from "@/redux/actions/alert";
import { authServices } from "services/auth.services";
import Card from "components/modules/farm/farmCard";
import _objI from "@/utils/_objI";
import { deleteFarm } from "services/farms.services";
import SwitchButtons from "@/components/modules/farm/switchButtons";
import {
  clearField,
  clearGeoField,
  readTreeFieldsLegend,
  readTreeGeoFields,
  setTreeFieldData,
} from "@/redux/actions/tree/fields";
import {
  clearPivotCropField,
  clearPivotGeoCropField,
  readPivotFields,
  readPivotGeoCropFieldsWithFarmId,
  readPivotGeoFields,
  setCropFieldData,
} from "@/redux/actions/pivot/fields";
import {
  readFarmImagesDates,
  readaAllValvesWithFarmId,
  setSensingData,
} from "@/redux/actions/tree/sensing";
const DeleteModal = dynamic(
  () => import("components/common/confirm-delete-modal.component"),
  { ssr: false }
);
const Table = dynamic(() => import("@/components/common/table"), {
  ssr: false,
});
const LimitExceeded = dynamic(() => import("components/common/limitExceeded"), {
  ssr: false,
});
type Reducer = {
  farmsReducer?: any;
  treeFieldsReducer?: any;
  navReducer?: any;
  cropFieldsReducer?: any;
  sensingReducer?: any;
  soilSamplesReducer?: any;
};
const Index = () => {
  const dispatch: any = useDispatch();
  const { t, i18n } = useTranslation();
  const [active, setActive] = useState("card");
  const [showModel, setShowModel] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [showLimitExceeded, setShowLimitExceeded] = useState(false);
  const router = useRouter();
  const { origin_farm_dates, min, max } = useSelector(
    (state: Reducer) => state.sensingReducer,
    shallowEqual
  );
  const { field, geo_field, crop_field, one_geo_crop_field } = useSelector(
    (state: Reducer) => state.cropFieldsReducer
  );

  const canCreate =
    authServices?.userValue?.policy_data?.farm_integration?.actions?.includes?.(
      "create"
    );
  const canDelete =
    authServices?.userValue?.policy_data?.farm_integration?.actions?.includes?.(
      "delete"
    );
  const { data: treesCreationStatus, isLoading: treesCreationStatusIsLoading } =
    useSwrGet(
      `/farmssss/all`,
      process.env.NEXT_PUBLIC_BASE_URL +
        `farms/trees-creation-status/${authServices?.userValue?.org_id}/`
    );
  const {
    data: farmsData,
    isLoading,
    mutate,
  } = useSwrGet(
    `/farmsss/all`,
    process.env.NEXT_PUBLIC_BASE_URL +
      `organizations/${authServices?.userValue?.org_id}/list-farms/`
  );
  const limitExceededCheck = () => {
    if (treesCreationStatus.status === true) {
      // Clear various fields and geo fields
      dispatch(clearField());
      dispatch(clearGeoField());
      dispatch(clearPivotCropField());
      dispatch(clearPivotGeoCropField());

      // Navigate to the "farms/new" route
      router.push("farms/new");

      // Clear farm and geo farm data
      dispatch(clearFarm());
      dispatch(clearGeoFarm());
    } else {
      // Set the state to show the limit exceeded message
      setShowLimitExceeded(true);
    }
  };

  const viewFarm = async (data) => {
    if (
      _objI({ ...field, ...geo_field }) ||
      _objI({ ...crop_field, ...one_geo_crop_field })
    ) {
      dispatch(clearField());
      dispatch(clearGeoField());
      dispatch(clearPivotCropField());
      dispatch(clearPivotGeoCropField());
    }
    dispatch(readTreeGeoFields(data.id));

    dispatch(setCropFieldData({ key: "crop_field_location", value: {} }));
    // Fetch gboundary data
    dispatch(
      setTreeFieldData({
        key: "geofield",
        value: {},
      })
    );
    let res = await navigationServices.getGboundry(data.gboundary_id);

    if (res) {
      // Update farm and geo_farm data in the state
      dispatch(setFarmData({ key: "farm", value: { ...data } }));
      dispatch(setFarmData({ key: "geo_farm", value: { ...res.data } }));
      dispatch(setTableTabs({ key: "field_type", value: "reg-legend" }));
      dispatch(readTreeFieldsLegend("reg-legend", data.id));
      // Navigate to different routes based on farming_type
      const routePath =
        data.farming_type === "tree" ? "/nav/tree" : "/nav/crop";
      router.push(routePath);
      dispatch(setTreeTab(data.farming_type === "tree" ? 1 : 4));
    } else {
      // Display an error alert if fetching data fails
      dispatch(
        setAlert({
          message: "Something went wrong!!",
          status: "error",
          priority: "high",
        })
      );
    }
  };

  const editFarm = async (data) => {
    // Clear existing farm and tree field data in the state
    dispatch(setFarmData({ key: "farm", value: {} }));
    dispatch(setFarmData({ key: "geo_farm", value: {} }));
    dispatch(setTreeFieldData({ key: "field", value: {} }));
    dispatch(setTreeFieldData({ key: "geo_field", value: {} }));

    // Fetch gboundary data and update state accordingly
    const gboundaryData = await navigationServices.getGboundry(
      data.gboundary_id
    );

    if (gboundaryData) {
      // Set farm form step and update farm and geo_farm data in the state
      dispatch(setFarmFormStep(1));
      dispatch(setFarmData({ key: "farm", value: { ...data } }));
      dispatch(
        setFarmData({ key: "geo_farm", value: { ...gboundaryData.data } })
      );

      // Navigate to the appropriate edit route based on farming_type
      const editRoute = "/farms/edit";
      router.push(editRoute);

      // Set the current farming type in the state
      const currentType = data.farming_type === "tree" ? "tree" : "crop";
      dispatch(setCurrentType(currentType));
    }
  };

  const tableActions = [
    {
      title: "view",
      async action(row: any) {
        viewFarm(row.original);
      },
    },
    {
      title: "edit",
      async action(row: any) {
        editFarm(row.original);
      },
    },
    {
      title: "delete",
      action(row: any) {
        canDelete
          ? (setShowModel(!showModel), setDeleteId(Number(row.original.id)))
          : dispatch(
              setNotAuthorizedAlertData({ key: "showModal", value: true })
            );
      },
    },
  ];

  const renderCard = (data, index) => (
    <Card
      data={data}
      setShowModel={setShowModel}
      setDeleteId={setDeleteId}
      showModel={showModel}
      viewFarm={viewFarm}
      editFarm={editFarm}
    />
  );

  const renderTable = (data) => (
    <Table
      data={data}
      columns={columns(t)}
      tableAction={tableActions}
      fillPage={true}
    />
  );
  const farmsDataFiltered =
    authServices?.userValue?.supervisory_data?.farms_ids || [];

  const renderContent = () => {
    if (farmsDataFiltered.length !== 0) {
      const filteredResults = farmsData?.filter?.((i) =>
        farmsDataFiltered.includes(i?.id)
      );

      return active === "card"
        ? filteredResults?.map?.(renderCard) ?? <div></div>
        : renderTable(filteredResults || []);
    }

    return active === "card"
      ? farmsData?.map?.(renderCard) ?? <div></div>
      : renderTable(farmsData || []);
  };

  if (isLoading || treesCreationStatusIsLoading) {
    return <Loader />;
  }

  if (
    authServices?.userValue?.super_admin ||
    authServices?.userValue?.farm_role == "agriculture_engineer"
  ) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <div className="flex flex-col items-center justify-center">
          <div className="w-[170.08px] md:w-[340.16px] h-[53.15px] md:h-[106.3px] relative">
            <Image layout="fill" src={Logo} alt="img" />
          </div>
          {/*           <div className="text-xl font-bold ">Welcome To Platfarm</div>
           */}{" "}
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={`${
          i18n.language === "en" ? "font-baloo" : "font-Almarai"
        } w-full h-full min-h-full    py-2 px-5 `}
      >
        <div className="flex items-center justify-between w-full capitalize">
          <div className="xl:text-[36px] mb-5 text-[30px]  capitalize">
            {t("Region Listing")}
          </div>
          <div className="flex items-center justify-between gap-4 mt-3 overflow-scroll ">
            <SwitchButtons active={active} setActive={setActive} />
            <Button
              title={t("Create")}
              className="px-6 py-1 text-white rounded-lg bg-main text-md "
              click={() =>
                canCreate
                  ? limitExceededCheck()
                  : dispatch(
                      setNotAuthorizedAlertData({
                        key: "showModal",
                        value: true,
                      })
                    )
              }
              icon={() => (
                <div className="w-[12px] h-[12px] relative">
                  <Image layout="fill" src={Plus} alt="img" />
                </div>
              )}
            />
          </div>
        </div>

        <div className="flex flex-wrap gap-5 ">{renderContent()}</div>
        <DeleteModal
          id={deleteId ?? null}
          setShowModel={setShowModel}
          showModel={showModel}
          deleteTrigger={deleteFarm}
          mutate={mutate}
        />
        <LimitExceeded
          show={showLimitExceeded}
          setShow={setShowLimitExceeded}
        />
      </div>
    );
  }
};

export default Index;
