import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import Cookie from "js-cookie";
import { setAlert } from "../actions/alert";

const startInsideReload = (title) => (dispatch) => {
  dispatch({ type: `START_${title.toUpperCase()}S_RELOAD` });
};

const finishedInsideReload = (title) => (dispatch) => {
  dispatch({ type: `FINISHED_${title.toUpperCase()}S_RELOAD` });
};

export const post = ({
  headers = {
    Authorization: `Bearer ${Cookie.get("token")}`,
  },
  title,
  successType = `CREATE_${title.toUpperCase()}`,
  errorType = `${title.toUpperCase()}_ERROR`,
  startReload = startInsideReload,
  finishedReload = finishedInsideReload,
  url,
  noReload = false,
  formData = {},
}) => {
  return async (dispatch) => {
    const onSuccess = (success) => {
      dispatch({
        type: successType,
        payload: success.data,
      });

      /* dispatch(
        setAlert({
          message: `${title} successfully created`,
          status: "success",
          priority: "high",
        })
      ); */
      !noReload && dispatch(finishedReload(title));
      return success;
    };
    const onError = (error) => {
      dispatch({
        type: errorType,
        payload: {
          type: successType,
          date: new Date(),
          id: uuidv4(),
        },
      });

      if (
        (typeof error.response.data === "string" &&
          error.response.data.includes("<!DOCTYPE html>")) ||
        (typeof error.response.data === "string" &&
          error.response.data.includes("<html>"))
      ) {
        /*  dispatch(
          setAlert({
            message: `error: Something Wrong!`,
            status: "error",
            priority: "high",
          })
        ); */
        !noReload && dispatch(finishedReload(title));

        return error;
      } else {
        Object?.entries?.(error?.response?.data)
          ?.map?.(([k, v]) => {
            return { [k]: Array.isArray(v) ? v[0] : v };
          })
          ?.map?.((alert) => {
            dispatch(
              setAlert({
                message: Object.keys(alert) + ": " + Object.values(alert),
                status: "error",
                priority: "high",
              })
            );
          });

        !noReload && dispatch(finishedReload(title));

        return error;
      }
    };
    try {
      !noReload && dispatch(startReload(title));
      const success = await axios.post(url, formData, {
        headers: headers,
      });
      return onSuccess(success);
    } catch (error) {
      return onError(error);
    }
  };
};
