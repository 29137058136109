export const SET_SENSING_DATA = "SET_SENSING_DATA";
export const READ_FARM_IMAGES_DATES = "READ_FARM_IMAGES_DATES";
export const READ_FARM_PIVOTS_DETAILS = "READ_FARM_PIVOTS_DETAILS";
export const REFRESH_MAP = "REFRESH_MAP";
export const SENSING_ERROR = "SENSING_ERROR";
export const CLEAR_SENSING = "CLEAR_SENSING";
export const START_SENSINGS_RELOAD = "START_SENSINGS_RELOAD";
export const FINISHED_SENSINGS_RELOAD = "FINISHED_SENSINGS_RELOAD";
export const READ_SENSING_CHARTS = "READ_SENSING_CHARTS";
export const READ_CLUSTER_IMAGES_DATES = "READ_CLUSTER_IMAGES_DATES";
export const READ_CROP_FIELD_IMAGES_DATES = "READ_CROP_FIELD_IMAGES_DATES";
export const READ_ALL_FARM_CLUSTERS = "READ_ALL_FARM_CLUSTERS";
