import {
  SET_TREE_TAB,
  TOGGLE_DETAILS,
  TOGGLE_WEATHER,
  TABLE_TAPS,
  TOGGLE_LAYER,
  ACTIVE_NAVIGATE,
  DEACTIVE_NAVIGATE,
  TOGGLE_SUB_DETAILS,
  TOGGLE_SPRAY,
  TOGGLE_ADD_CLUSTER,
} from "@/redux/types/nav";
export const setTreeTab = (payload) => (dispatch) => {
  dispatch({ type: SET_TREE_TAB, payload });
};
export const toggleDetails = (payload) => (dispatch) => {
  dispatch({ type: TOGGLE_DETAILS, payload });
};
export const toggleSubDetails = (payload) => (dispatch) => {
  dispatch({ type: TOGGLE_SUB_DETAILS, payload });
};
export const toggleWeather = () => {
  return { type: TOGGLE_WEATHER };
};
export const toggleSpray = () => {
  return { type: TOGGLE_SPRAY };
};
export const setTableTabs = (payload) => (dispatch) => {
  dispatch({ type: TABLE_TAPS, payload });
};
export const toggleLayer = (payload) => (dispatch) => {
  dispatch({ type: TOGGLE_LAYER, payload });
};
export const deActiveNavigate = () => (dispatch) => {
  dispatch({ type: DEACTIVE_NAVIGATE });
};
export const activeNavigate = () => (dispatch) => {
  dispatch({ type: ACTIVE_NAVIGATE });
  setTimeout(() => dispatch({ type: DEACTIVE_NAVIGATE }), 3000);
};

export const toggleAddCluster = (payload) => (dispatch) => {
  dispatch({ type: TOGGLE_ADD_CLUSTER, payload });
};
