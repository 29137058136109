import {
  READ_WEATHERS,
  START_WEATHERS_RELOAD,
  FINISHED_WEATHERS_RELOAD,
  WEATHER_ERROR,
} from "../types/weather";

const initialState = {
  weather: [],
  loading: false,
  readable: false,
  errors: [],
};
export default function weatherReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case READ_WEATHERS:
      return {
        ...state,
        weather: payload,
        readable: true,
      };

    case START_WEATHERS_RELOAD:
      return {
        ...state,
        loading: true,
      };
    case FINISHED_WEATHERS_RELOAD:
      return {
        ...state,
        loading: false,
      };
    case WEATHER_ERROR:
      return {
        ...state,
        errors: [...state.errors, payload],
      };
    default:
      return state;
  }
}
