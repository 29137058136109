import {
  SET_SENSING_DATA,
  READ_FARM_IMAGES_DATES,
  READ_FARM_VALVES_DETAILS,
  VALVE_REFRESH_MAP,
  START_SENSINGS_RELOAD,
  FINISHED_SENSINGS_RELOAD,
  SENSING_ERROR,
  CLEAR_SENSING,
  READ_SENSING_CHARTS,
  READ_VALVES_WITH_FILTER,
  READ_ALL_VALVE_WITH_FARM_ID,
  GEO_VALVE,
  READ_VALVES_BY_FIELD_ID,
  SHOW_COLORS,
  CLEAR_VALVES,
} from "../../types/tree/sensing";
import _ from "lodash";
const initialState = {
  index: "NDVI",
  time: 2,
  farm_dates: [],
  origin_farm_dates: [],
  farm_crops_details: [],
  min: "",
  max: "",
  valveRefreshMap: true,
  sensingCharts: [],
  geo_field_valves: {},
  errors: [],
  valves: [],
  geo_valves: [],
  geo_valve: {},
  showColors: false,
  farm_id: "",
};
export default function sensingTreeReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_SENSING_DATA:
      return {
        ...state,
        [payload.key]: payload.value,
      };
    case READ_FARM_IMAGES_DATES:
      return {
        ...state,
        min:
          new Date(_.min(payload.map((date) => new Date(date.image_date))))
            ?.toISOString()
            .split("T")[0] ?? "",
        max:
          new Date(_.max(payload.map((date) => new Date(date.image_date))))
            ?.toISOString()
            .split("T")[0] ?? "",
        farm_dates: payload?.map?.((p, i) => {
          return { ...p, active: i + 1 === payload?.length ? true : false };
        }),
        origin_farm_dates: payload.map((p, i) => {
          return { ...p, active: i + 1 === payload?.length ? true : false };
        }),
      };
    case READ_VALVES_WITH_FILTER:
      return {
        ...state,
        valves: payload?.results,
      };
    case READ_FARM_VALVES_DETAILS:
      return {
        ...state,
        farm_crops_details: payload,
      };

    case VALVE_REFRESH_MAP:
      return {
        ...state,
        valveRefreshMap: payload,
      };
    case READ_SENSING_CHARTS:
      return {
        ...state,
        sensingCharts: payload,
      };
    case CLEAR_SENSING:
      return {
        ...state,
        geo_field: {},
      };
    case START_SENSINGS_RELOAD:
      return {
        ...state,
        sensingCharts: payload,
      };
    case FINISHED_SENSINGS_RELOAD:
      return {
        ...state,
        loading: false,
      };
    case SENSING_ERROR:
      return {
        ...state,
        errors: [...state.errors, payload],
      };
    case READ_ALL_VALVE_WITH_FARM_ID:
      return {
        ...state,
        geo_valves: payload,
      };
    case SHOW_COLORS:
      return {
        ...state,
        showColors: payload,
      };
    case READ_VALVES_BY_FIELD_ID:
      return {
        ...state,
        geo_field_valves: payload,
      };
    case CLEAR_VALVES: {
      return {
        ...state,
        geo_field_valves: {},
        valves: [],
        geo_valves: [],
      };
    }
    default:
      return state;
  }
}
