import { SET_DRAW_VALVE_DATA } from "../../types/valves/drawValves";

const initialState = {
  valve_id: "",
  gboundary: {},
  all_valves: true,
};
export default function drawValveDataReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_DRAW_VALVE_DATA:
      return {
        ...state,
        [payload.key]: payload.value,
      };

    default:
      return state;
  }
}
