import {
  SET_FARM_DATA,
  READ_FARMS,
  READ_ONE_FARM,
  CREATE_FARM,
  UPDATE_FARM,
  DELETE_FARM,
  CLEAR_FARM,
  CLEAR_GEO_FARM,
  START_FARMS_RELOAD,
  FINISHED_FARMS_RELOAD,
  FARM_ERROR,
  SET_FORM_FARM_STEP,
  SET_FARM_TYPE,
  CLEAR_FARMS,
} from "../types/farms";
import { authServices } from "services/auth.services";
const initialState = {
  farms: [], // all farms
  farm: {}, /// read one farm
  geo_farm: {}, // one farm geo
  currentType: "tree",
  form_farm: {
    // create farm
    org_id: authServices?.userValue?.org_id ?? "",
    farming_type: authServices?.userValue?.farming_type ?? "",
    farm_id: "",
    farm_code: "",
    field_id: "",
    marker_name: null,
    center: [25.451210547639096, 30.559009602893124],
    zoom: 6,
    farm_geo: {},
    field_geo: {},
    icon: "../../../../markers/sensor/7 days-sensor.svg",
  },
  loading: false,
  readable: false,
  step: 1,
  errors: [],
};
export default function farmsReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_FARM_DATA:
      return {
        ...state,
        [payload.key]: {
          ...state[payload.key],
          ...payload.value,
        },
      };
    case READ_FARMS:
      return {
        ...state,
        farms: payload.results,
        readable: true,
      };
    case READ_ONE_FARM:
      return {
        ...state,
        farm: payload,
      };

    case SET_FARM_TYPE:
      return { ...state, currentType: payload };
    case CREATE_FARM:
      return {
        ...state,
        farms: [payload, ...state.farms],
        farm: payload,
      };
    case UPDATE_FARM:
      return {
        ...state,
        farms: [
          ...state.farms.map((farm) =>
            farm.id === payload.farm.id ? payload.farm : farm
          ),
        ],
      };
    case DELETE_FARM:
      return {
        ...state,
        farms: state.farms.filter((farm) => farm.id !== payload.farm.id),
      };
    case CLEAR_FARM:
      return {
        ...state,
        farm: {},
      };
    case CLEAR_GEO_FARM:
      return {
        ...state,
        geo_farm: {},
      };

    case START_FARMS_RELOAD:
      return {
        ...state,
        loading: true,
      };
    case FINISHED_FARMS_RELOAD:
      return {
        ...state,
        loading: false,
      };
    case FARM_ERROR:
      return {
        ...state,
        // errors: [...state.errors, payload],
      };
    case SET_FORM_FARM_STEP:
      return {
        ...state,
        step: payload,
      };
    case CLEAR_FARMS:
      return {
        ...state,
        farms: [],
      };
    default:
      return state;
  }
}
