import { SET_POLICY_DATA } from "../types/access-policy";

const initialState = {
  data: {},
  response: "",
};
export default function policyDataReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_POLICY_DATA:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
}
