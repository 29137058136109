import {
  SET_ELECTRICITYNETWORK_DATA,
  READ_ELECTRICITYNETWORKS,
  READ_ONE_ELECTRICITYNETWORK,
  CREATE_ELECTRICITYNETWORK,
  UPDATE_ELECTRICITYNETWORK,
  DELETE_ELECTRICITYNETWORK,
  CLEAR_ELECTRICITYNETWORK,
  START_ELECTRICITYNETWORKS_RELOAD,
  FINISHED_ELECTRICITYNETWORKS_RELOAD,
  ELECTRICITY_NETWORKERROR,
  READ_ELECTRICITY_ITEMS,
} from "../../types/tree/electricity-networks";

const initialState = {
  electricity_network_items: [],
  electricity_networks: [],
  electricity_network: {},
  geo_electricity_network: {},
  form_electricity_network: {},
  loading: false,
  readable: false,
  errors: [],
};
export default function electricityNetworksReducer(
  state = initialState,
  action
) {
  const { type, payload } = action;
  switch (type) {
    case SET_ELECTRICITYNETWORK_DATA:
      return {
        ...state,
        [payload.key]: {
          ...state[payload.key],
          ...payload.value,
        },
      };
    case READ_ELECTRICITYNETWORKS:
      return {
        ...state,
        electricity_networks: [...payload.results],
      };
    case READ_ELECTRICITY_ITEMS:
      return {
        ...state,
        electricity_network_items: [...payload],
        readable: true,
      };
    case READ_ONE_ELECTRICITYNETWORK:
      return {
        ...state,
        electricity_network: { ...payload },
      };

    case CREATE_ELECTRICITYNETWORK:
      return {
        ...state,
        electricity_networks: [payload, ...state.electricity_networks],
        electricity_network: payload,
      };
    case UPDATE_ELECTRICITYNETWORK:
      return {
        ...state,
        electricity_networks: [
          ...state.electricity_networks.map((electricity_network) =>
            electricity_network.id === payload.electricity_network.id
              ? payload.electricity_network
              : electricity_network
          ),
        ],
      };
    case DELETE_ELECTRICITYNETWORK:
      return {
        ...state,
        electricity_networks: [
          ...state.electricity_networks.filter(
            (electricity_network) =>
              electricity_network.id !== payload.electricity_network.id
          ),
        ],
      };

    case CLEAR_ELECTRICITYNETWORK:
      return {
        ...state,
        electricity_network: {},
      };

    case START_ELECTRICITYNETWORKS_RELOAD:
      return {
        ...state,
        loading: true,
      };
    case FINISHED_ELECTRICITYNETWORKS_RELOAD:
      return {
        ...state,
        loading: false,
      };
    case ELECTRICITY_NETWORKERROR:
      return {
        ...state,
        errors: [...state.errors, payload],
      };

    default:
      return state;
  }
}
