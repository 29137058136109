export const slackblocks = (error, router, authServices) => `[
    {
      type: "header",
      text: {
        type: "plain_text",
        text: "Heads up! New Crash Detected",
        emoji: true,
      },
    },
    {
      type: "section",
      fields: [
        {
          type: "mrkdwn",
          text: "*Error Message:*\n${error.message}",
        },
        {
          type: "mrkdwn",
          text: "*Page:*\n${router.pathname}",
        },
      ],
    },
    {
      type: "section",
      fields: [
        {
          type: "mrkdwn",
          text: "*UserName:*\n${authServices.userValue.user_name}",
        },
        {
          type: "mrkdwn",
          text: "*Organization:*\n${authServices.userValue.org_id}",
        },
      ],
    },
  ]`;
