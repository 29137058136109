export const SET_ELECTRICITYNETWORK_DATA = "SET_ELECTRICITYNETWORK_DATA";

export const READ_ELECTRICITYNETWORKS = "READ_ELECTRICITYNETWORKS";
export const READ_ONE_ELECTRICITYNETWORK = "READ_ONE_ELECTRICITYNETWORK";
export const CREATE_ELECTRICITYNETWORK = "CREATE_ELECTRICITYNETWORK";
export const READ_ELECTRICITY_ITEMS = "READ_ELECTRICITY_ITEMS";
export const UPDATE_ELECTRICITYNETWORK = "UPDATE_ELECTRICITYNETWORK";
export const DELETE_ELECTRICITYNETWORK = "DELETE_ELECTRICITYNETWORK";
export const ELECTRICITY_NETWORKERROR = "ELECTRICITY_NETWORKERROR";
export const CLEAR_ELECTRICITYNETWORK = "CLEAR_ELECTRICITYNETWORK";
export const START_ELECTRICITYNETWORKS_RELOAD =
  "START_ELECTRICITYNETWORKS_RELOAD";
export const FINISHED_ELECTRICITYNETWORKS_RELOAD =
  "FINISHED_ELECTRICITYNETWORKS_RELOAD";

export const CLEAR_ELECTRICITYNETWORKS = "CLEAR_ELECTRICITYNETWORKS";
