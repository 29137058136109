import { BehaviorSubject } from "rxjs";
import axios from "axios";
import router from "next/router";
import jwt from "jwt-decode";
import Cookie from "js-cookie";
const authSubject = new BehaviorSubject(
  process.browser && JSON.parse(localStorage.getItem("user"))
);
export const authServices = {
  user: authSubject.asObservable(),
  get userValue() {
    return authSubject.value;
  },
  login,
  logout,
  revalidateToken,
  forgetPassword,
  contactUs,
  changePassword,
  checkToken,
  refreshToken,
};
type decodedToken = {
  exp?: any;
  policy_id?: any;
};
async function forgetPassword(data) {
  if (typeof window !== "undefined") {
    data.redirect_url = `${window.location.hostname}/change-password/`;
    try {
      let res = await axios.post(
        process.env.NEXT_PUBLIC_BASE_URL + "request-reset-password/",
        data
      );
      if (res.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  } else {
    return false;
  }
}
async function contactUs(data) {
  try {
    let res = await axios.post(
      process.env.NEXT_PUBLIC_BASE_URL + "contacts/",
      data
    );
    if (res.status === 201 || res.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
}
async function login(data) {
  try {
    let res = await axios.post(
      process.env.NEXT_PUBLIC_BASE_URL + "token/",
      data
    );
    if (res.status === 200) {
      let decodedToken: decodedToken = jwt(res.data.access) || {};
      let user = {
        ...decodedToken,
        ...res.data,
      };
      authSubject.next(user);
      Cookie.set("token", user.access, { expires: 1 });
      Cookie.set("user", JSON.stringify(user));
      localStorage.setItem("user", JSON.stringify(user));
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
}
function checkToken() {
  if (Cookie.get("token")) {
    let decodedToken: decodedToken = jwt(Cookie.get("token"));
    if (
      new Date(new Date(0).setUTCSeconds(decodedToken.exp)) >
      new Date(Date.now())
    ) {
      return true;
    }
    return false;
  }
  return false;
}
async function revalidateToken() {
  Cookie.set("out", String(Number(Cookie.get("out") ?? 0) + 1));
  let decodedToken: decodedToken | undefined = jwt(Cookie.get("token"));
  if (
    new Date(new Date(0).setUTCSeconds(decodedToken.exp)) < new Date(Date.now())
  ) {
    logout();
  }
  if (
    new Date(new Date(0).setUTCSeconds(decodedToken.exp)) <
    new Date(Date.now() + 1000 * 60 * 30)
  ) {
    try {
      let res = await axios.post(
        process.env.NEXT_PUBLIC_BASE_URL + "token/refresh/",
        { refresh: authServices.userValue.refresh }
      );
      if (res.status === 200) {
        console.log(res.data.access);
        let user = {
          ...authServices.userValue,
          access: res.data.access,
        };
        authSubject.next(user);
        Cookie.set("in", String(Number(Cookie.get("in") ?? 0) + 1));
        console.log("done");
        // Cookie.set("token", user.access, { secure: true, expires: 1 });
        Cookie.set("token", user.access, { expires: 1 });
        Cookie.set("user", JSON.stringify(user));
        localStorage.setItem("user", JSON.stringify(user));
      } else {
      }
    } catch (e) {
      logout();
    }
  }
}
async function refreshToken() {
  Cookie.set("out", String(Number(Cookie.get("out") ?? 0) + 1));
  try {
    let res = await axios.post(
      process.env.NEXT_PUBLIC_BASE_URL + "token/refresh/",
      { refresh: authServices.userValue.refresh }
    );
    if (res.status === 200) {
      console.log(res.data.access);
      let user = {
        ...authServices.userValue,
        access: res.data.access,
      };
      authSubject.next(user);
      Cookie.set("in", String(Number(Cookie.get("in") ?? 0) + 1));

      // Cookie.set("token", user.access, { secure: true, expires: 1 });
      Cookie.set("token", user.access, { expires: 1 });
      Cookie.set("user", JSON.stringify(user));
      localStorage.setItem("user", JSON.stringify(user));
    } else {
    }
  } catch (e) {
    logout();
  }
}
async function changePassword(data) {
  try {
    let res = await axios.patch(
      process.env.NEXT_PUBLIC_BASE_URL + "password-reset-complete/",
      data
    );
    if (res.status === 201 || res.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
}

function logout() {
  localStorage.removeItem("user");
  authSubject.next(null);
  Cookie.remove("token");
  router.push({
    pathname: "/auth/login",
  });
}
