import { SET_NOT_AUTHORIZED_ALERT } from "../types/notAuthorizedAlert";

const initialState = {
  showModal: false,
};
export default function notAuthorizedAlertReducer(
  state = initialState,
  action
) {
  const { type, payload } = action;

  switch (type) {
    case SET_NOT_AUTHORIZED_ALERT:
      return {
        ...state,
        [payload.key]: payload.value,
      };

    default:
      return state;
  }
}
