export const columns = (t: any) => [
  {
    Header: t("Region Code"),
    accessor: "farm_code",
    Cell: ({ cell: { value } }) => {
      return value ?? "-";
    },
  },
  {
    Header: t("Region Name"),
    accessor: "farm_name",
    Cell: ({ cell: { value } }) => {
      return value ?? "-";
    },
  },
  {
    Header: t("Region Address"),
    accessor: "address",
    Cell: ({ cell: { value } }) => {
      return value ?? "-";
    },
  },
  {
    Header: t("Farms"),
    accessor: "fields_number",
    Cell: ({ cell: { value } }) => {
      return value ?? "-";
    },
  },
  {
    Header: t("No. of Trees"),
    accessor: "trees",
    Cell: ({ cell: { value } }) => {
      return value ?? "-";
    },
  },
];
