export const SET_WATERNETWORK_DATA = "SET_WATERNETWORK_DATA";

export const READ_WATERNETWORKS = "READ_WATERNETWORKS";
export const READ_ONE_WATERNETWORK = "READ_ONE_WATERNETWORK";
export const CREATE_WATERNETWORK = "CREATE_WATERNETWORK";
export const UPDATE_WATERNETWORK = "UPDATE_WATERNETWORK";
export const DELETE_WATERNETWORK = "DELETE_WATERNETWORK";
export const WATER_NETWORKERROR = "WATER_NETWORKERROR";
export const READ_WATERNETWORK_ITEMS = "READ_WATERNETWORK_ITEMS";

export const CLEAR_WATERNETWORK = "CLEAR_WATERNETWORK";
export const START_WATERNETWORKS_RELOAD = "START_WATERNETWORKS_RELOAD";
export const FINISHED_WATERNETWORKS_RELOAD = "FINISHED_WATERNETWORKS_RELOAD";

export const CLEAR_WATERNETWORKS = "CLEAR_WATERNETWORKS";
