export const SET_INVENTORIES_DATA = "SET_INVENTORIES_DATA";
export const READ_INVENTORIES = "READ_INVENTORIES";
export const READ_GEO_INVENTORIES = "READ_GEO_INVENTORIES";
export const READ_ONE_INVENTORY = "READ_ONE_INVENTORY";
export const READ_INVENTORIES_ACTIVITIES = "READ_INVENTORIES_ACTIVITIES";
export const READ_INVENTORIES_STATS = "READ_INVENTORIES_STATS";
export const CLEAR_GEO_INVENTORIES = "CLEAR_INVENTORIES";
export const START_INVENTORIES_RELOAD = "START_INVENTORIES_RELOAD";
export const FINISHED_INVENTORIES_RELOAD = "FINISHED_INVENTORIES_RELOAD";
export const INVENTORIES_ERROR = "INVENTORIES_ERROR";
export const READ_INVENTORIES_BY_CATEGORIES = "READ_INVENTORIES_BY_CATEGORIES";
export const READ_INVENTORIES_GLOBAL_ITEMS = "READ_INVENTORIES_GLOBAL_ITEMS";
export const CREATE_IVENTORY_ITEM = "CREATE_IVENTORY_ITEM";
export const SET_INVENTORIES_SUPERVISORS = "SET_INVENTORIES_SUPERVISORS";
export const UPDATE_INVENTORY = "UPDATE_INVENTORY";
export const INVENTORY_TRANSACTION = "INVENTORY_TRANSACTION";
